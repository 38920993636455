import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import myImage from '../assets/images/cookies.png';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Typography,
  theme,
  Spin,
  Image,
} from 'antd';
import {
  useSignInMutation,
  useLazyGetUserQuery,
} from '../services/admin/adminAuth';
import { signIn as signInAction } from '../slices/admin/adminUserSlice';
import logo from '../assets/images/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store'; // Import your store's root state
import { inventoryApi } from '../services/admin/inventoryApi';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

interface FetchBaseQueryErrorData {
  detail?: string;
}

const SignIn: React.FC = () => {
  const { token } = useToken();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [signIn, { error, isLoading: loading, data }] = useSignInMutation();
  const [getUser] = useLazyGetUserQuery();
  // Select the token from the store
  const sessionToken = useSelector((state: RootState) => state.adminUser.token);

  // Error handling function
  function getErrorMessage(error: FetchBaseQueryError): string {
    if ('data' in error) {
      // If error has data property, assert its type
      const fetchBaseQueryErrorData = (error as FetchBaseQueryError)
        .data as FetchBaseQueryErrorData;
      return (
        fetchBaseQueryErrorData?.detail ??
        'Failed to sign in. Please try again.'
      );
    } else {
      // If error doesn't have data property
      return 'Failed to sign in. Please try again.';
    }
  }

  const handleSignIn = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    try {
      const result = await signIn({
        email: email.trim().toLowerCase(),
        password: password.trim(),
      }).unwrap();
      dispatch(
        signInAction({ token: result.access_token, email: email.trim() })
      );
      // if (result.access_token) {
      //   navigate("/dashboard/products");
      // }
    } catch (err) {
      console.error('Failed to sign in:', err);
    }
  };

  const navigateToForgotPassword = () => {
    navigate('/forgot-password');
  };

  //hooks
  useEffect(() => {
    if (sessionToken) {
      // Optional: Verify token by fetching user details if needed
      getUser()
        .unwrap()
        .then((result) => {
          dispatch(inventoryApi.util.invalidateTags(['Campaign','Orders']));
          console.log(result,result.scope === 'student')
          if (result.scope === 'student') {
            navigate('/dashboard/user-dashboard');
          }
          else if (result.scope === 'rep') {
            navigate('/dashboard/campaigns');
          } else {
            navigate('/dashboard/products');
          }

          // navigate("/dashboard/products");
        })
        .catch(() => {
          // Optionally handle the case where the token is invalid
        });
    }
  }, [sessionToken, getUser, navigate]);

  //styles
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      margin: '0 auto',
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: '450px',
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: 'center',
      width: '100%',
    },
    header: {
      marginBottom: token.marginXL,
    },
    borderRadius: {
      borderRadius: 10,
      color: '#232323',
    },
    section: {
      alignItems: 'center',
      backgroundColor: token.colorBgContainer,
      display: 'flex',
      height: screens.sm ? '100vh' : 'auto',
      padding: screens.md ? `${token.sizeXXL}px 0px` : '0px',
    },
    rememberMe: {
      fontWeight: 500,
      fontSize: 16,
      color: '#232323',
    },
    forgotPassword: {
      fontWeight: 400,
      fontSize: 16,
      float: 'right',
      cursor: 'pointer',
      color: '#232323',
    },
    text: {
      color: token.colorTextSecondary,
      fontWeight: 400,
      fontSize: token.fontSizeHeading3,
      userSelect: 'none',
    },
    signInButton: {
      height: '54px',
    },
    title: {
      userSelect: 'none',
      fontWeight: 700,
      marginBottom: 12,
      color: '#232323',
    },
    imageContainer: {
      display: screens.md ? 'block' : 'none', // Hide on smaller screens
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      overflow: 'hidden',
      marginRight: 10,
    },
  };

  return (
    <section style={styles.section}>
      <div style={{  textAlign: 'center' , backgroundColor: "white", position:"absolute",left:10,top:20}}>
          <img
            src={logo} alt="Company Logo"
            style={{ width: '100%', maxWidth: '120px' }}
          />
        </div>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title level={1} style={styles.title}>
            Sign in
          </Title>
          <Text style={styles.text} type="secondary">
            Welcome back! Please enter your details below to sign in.
          </Text>
        </div>
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSignIn}
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input a valid Email!',
              },
            ]}
            getValueFromEvent={(e) => e.target.value.trim()} // Trims spaces
          >
            <Input placeholder="Email" style={styles.borderRadius} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Password"
              style={styles.borderRadius}
            />
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">
                {error
                  ? getErrorMessage(error as FetchBaseQueryError)
                  : 'Failed to sign in. Please try again.'}
              </Text>
            </Form.Item>
          )}
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>
                <Text style={styles.rememberMe}>Remember me</Text>
              </Checkbox>
            </Form.Item>
            <Text
              style={styles.forgotPassword}
              onClick={navigateToForgotPassword}
            >
              Forgot password?
            </Text>
          </Form.Item>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              style={styles.signInButton}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
        {loading && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Spin />
          </div>
        )}
      </div>
      <div style={styles.imageContainer}>
        <Image
          src={myImage} // Replace with the actual image path
          alt="Sign in Illustration"
          preview={false} // Disables zoom on click
          style={{
            height: 'calc(100vh - 20px)',
          }}
        />
      </div>
    </section>
  );
};

export default SignIn;
