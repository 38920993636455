import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Spin, Typography, message, Drawer, List, Checkbox, Select, Switch, Form, Row, Col, Flex } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useEditUserMutation, useGetUsersQuery, useGetGroupsQuery, useGetUserByIdQuery } from "../services/admin/inventoryApi";
import { useNavigate, useParams } from "react-router-dom";
import { User, Group } from "../types.ts";
import '../styles/AddUser.scss';
import Title from "antd/es/typography/Title";
import { DownOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import CurrencyInput from "react-currency-input-field";


const { Text } = Typography;
const { Option } = Select;

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<User>();
  const { data: userData, error: usersError, isLoading: usersLoading } = useGetUserByIdQuery({ id: parseInt(id || "0") });
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsQuery({ skip: 0, limit: 1000 ,
   //@ts-ignore
   is_rep_group:null});
  const [updateUser, { isLoading: updateLoading, isError: updateError, error }] = useEditUserMutation();

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    if (userData) {
      const user = userData;
      setValue("full_name", user?.full_name || '');
      setValue("email", user?.email || '');
      setValue("blurb", user?.blurb || '');
      setValue("scope", user?.scope || 'student'); // Updated to include scope
      setValue("in_leaderboard", user?.in_leaderboard || false);
      setValue("is_active", user?.is_active || false);
      setValue("raise_goal", user?.raise_goal || 0);
      setSelectedGroups(user?.groups || []);
    }
  }, [userData, setValue]);

  const validatePassword = (password: string) => {
    if (!password) return true; // No password provided, no need to validate
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[A-Za-z]/.test(password) || !/\d/.test(password)) {
      return "Password must contain both letters and numbers.";
    }
    return true;
  };

  const onSubmit = async (data: User) => {
    const payload: Partial<User> = { 
      ...data,
      id: parseInt(id || "0"),
      //@ts-ignore
      group_ids: selectedGroups.map(group => group.id)
    };

    if (!data.new_password) {
      delete payload.new_password;
    }


    try {
      // Perform the PATCH request
      //@ts-ignore
      await updateUser({ id: parseInt(id || "0"), ...payload }).unwrap();
      message.success('User updated successfully!');
      // navigate(-1);
    } catch (err) {
      console.error('Failed to update user:', err);
      message.error('Failed to update user. Please try again.');
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroups(prevGroups => {
      if (prevGroups.some(g => g.id === group.id)) {
        return prevGroups.filter(g => g.id !== group.id);
      }
      return [...prevGroups, group];
    });
  };

  if (usersLoading) return <Spin />;
  if (usersError) return <Text type="danger">Failed to fetch user data.</Text>;

  return (
    <div className="add-user-container">
       <Title level={2} className="editProduct">Edit User</Title>
      < Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-user-form">
      <Row>
            <Col flex={3}>
      <Form.Item
          label="Full Name"
          validateStatus={errors.full_name ? 'error' : ''}
          help={errors.full_name ? errors.full_name.message : ''}
        >
          <Controller
            name="full_name"
            control={control}
            rules={{ required: "Full Name is required" }}
            render={({ field }) => (
              <Input {...field} placeholder="Full Name" />
            )}
          />
          {errors.full_name && <Text type="danger">{errors.full_name.message}</Text>}
          </Form.Item>
          <Form.Item
          label="Scope"
          validateStatus={errors.scope ? 'error' : ''}
          help={errors.scope ? errors.scope.message : ''}
        >
          
          <Controller
            name="scope"
            control={control}
            rules={{ required: "Scope is required" }}
            render={({ field }) => (
              <Select {...field} placeholder="Select Scope">
                <Option value="student">Student</Option>
                <Option value="rep">Manager</Option>
              </Select>
            )}
          />
          {errors.scope && <Text type="danger">{errors.scope.message}</Text>}
        </Form.Item>
        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            render={({ field }) => (
              <TextArea {...field} placeholder="Blurb" rows={8} />
            )}
          />
        </Form.Item>
        <Form.Item
          label="New Password"
          validateStatus={errors.new_password ? 'error' : ''}
          help={errors.new_password ? errors.new_password.message : ''}
        >
          <Controller
            name="new_password"
            control={control}
            rules={{
              validate: validatePassword
            }}
            render={({ field }) => (
              <Input.Password {...field} placeholder="New Password" />
            )}
          />
          {errors.new_password && <Text type="danger">{errors.new_password.message}</Text>}
        </Form.Item>
        <Form.Item
          label="Groups"
          
        >
           <Input
        value={`Select Groups`}
        onClick={handleDrawerOpen}
        readOnly
        addonAfter={<DownOutlined onClick={handleDrawerOpen} />}
      />
          <List
            dataSource={selectedGroups}
            renderItem={group => (
              <List.Item key={group.id}>
                {group.name}
              </List.Item>
            )}
          />
        </Form.Item>
</Col>
<Col flex={2} style={{
              paddingLeft: 40
            }}>




          <Form.Item
          label="Email"
          validateStatus={errors.email ? 'error' : ''}
          help={errors.email ? errors.email.message : ''}
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Invalid email address"
              }
            }}
            render={({ field }) => (
              <Input {...field} placeholder="Email" />
            )}
          />
          {errors.email && <Text type="danger">{errors.email.message}</Text>}
          </Form.Item>

          <Form.Item
          label="Raise Goal"
          validateStatus={errors.raise_goal ? 'error' : ''}
          help={errors.raise_goal ? errors.raise_goal.message : ''}
        >
          <Controller
            name="raise_goal"
            control={control}
            rules={{ required: 'Raise goal is required' }}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
               placeholder="Please enter Money Goal"
               defaultValue={(userData?.raise_goal??0)/100}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>

      

       
          <Flex justify="space-between" align="center">
          {/* <Form.Item
          label="Show Name in Public Leaderboard"
          validateStatus={errors.is_active ? 'error' : ''}
          help={errors.is_active ? errors.is_active.message : ''}
        >
       
  <Controller
    name="in_leaderboard"
    control={control}
    render={({ field }) => (
      <Switch checked={field.value} onChange={field.onChange} />
    )}
  />
</Form.Item> */}

<Form.Item
          label="Is Active"
          validateStatus={errors.is_active ? 'error' : ''}
          help={errors.is_active ? errors.is_active.message : ''}
        >
  <Controller
    name="is_active"
    control={control}
    render={({ field }) => (
      <Switch checked={field.value} onChange={field.onChange} />
    )}
  />
</Form.Item>
</Flex>

</Col>
</Row>
        {updateError && (
          <div className="form-item">
            <Text type="danger">{error ? error.toString() : 'Failed to update user. Please try again.'}</Text>
          </div>
        )}

<Row justify={'end'}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            Save Change
          </Button>
          </Form.Item>
</Row>
      </Form>

      <Drawer
        title="Select Groups"
        width={400}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
          dataSource={groupsData?.data ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Checkbox
                checked={selectedGroups.some(g => g.id === group.id)}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default EditUser;
