import React from "react";
import { Form, Input, Button, message, Spin, Row, Col, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useUpdatePasswordMutation } from "../services/admin/adminAuth";
import { validatePasswordRules } from "../utils";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import "../styles/ChangePassword.scss";

const { Title } = Typography;

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [updatePasssword, { isLoading }] = useUpdatePasswordMutation();
  const activeToken = useSelector((state: RootState) => state.adminUser.token);

  const handleChangePassword = async () => {
    try {
      const values = await form.validateFields();
      const { currentPassword, newPassword } = values;
      const token = activeToken; 

      await updatePasssword({
        token: token ?? "",
        password: newPassword,
        currentPassword,
      }).unwrap();

      message.success("Password changed successfully!");
    } catch (err) {
      console.error("Failed to change password:", err);
      // @ts-ignore
      message.error(
        // @ts-ignore
        err?.data?.detail || "Failed to change password. Please try again."
      );
    }
  };

  const validatePassword = (_: any, value: any) => {
    if (!value || form.getFieldValue("newPassword") === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("The two passwords that you entered do not match!")
    );
  };

  return (
    <div className="edit-campaign-container" style={{
      marginTop: 40
    }}>
     
       
      <Title level={2} className="editProduct">Update Password</Title>
        <Form
        requiredMark={false}
          name="change_password_form"
          form={form}
          onFinish={handleChangePassword}
          layout="vertical"
          className="change-password-form"
        >
          <Form.Item
          className="current-password"
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password
           
              prefix={<LockOutlined />}
              type="password"
              placeholder="Current Password"
            />
          </Form.Item>

          <div 
          className="password-form"
         >
       
              <Form.Item
              className="new-password"
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    validator: validatePasswordRules,
                    message:
                      "Minimum length of at least 8 characters and contain both numeric and alphabetic characters.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="New Password"
                />
              </Form.Item>
            
            
              <Form.Item
               className="new-password"
                name="confirmNewPassword"
                label="Confirm New Password"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password
               
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Confirm New Password"
                />
              </Form.Item>
            
          </div>
          <Row  justify={{
  xs: "center",
  sm:"end"
}}  >
          <Form.Item 
          
          >
            
            <Button type="primary" htmlType="submit" loading={isLoading} >
              Change Password
            </Button>
          </Form.Item>
          </Row>

          {isLoading && (
            <Form.Item>
              <Spin />
            </Form.Item>
          )}
        </Form>
   
    </div>
  );
};

export default ChangePassword;
