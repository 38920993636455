import React from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { useAddCatalogMutation } from '../services/admin/inventoryApi';
import '../styles/AddProduct.scss';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Form, Typography, Row } from 'antd';

const { TextArea } = Input;
const { Title } = Typography;

const AddCatalog: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors,isDirty } } = useForm({
    defaultValues: {
      name: '',
      blurb: '',
      price_schedules: ''
    }
  });

  const [addCatalog, { isLoading, isError, isSuccess }] = useAddCatalogMutation();
  const navigate = useNavigate();

  const onSubmit = async (data: FieldValues) => {
    try {
      
        data.price_schedules = {}; // Parse the JSON string to object if provided
      
      await addCatalog(data).unwrap();
      console.log('Catalog added successfully:', data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to add catalog:', error);
    }
  };

  return (
    <div className="add-product-container">
      <Title level={2} className="editProduct">Add New Catalog</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-product-form">
        <Form.Item
          label="Name"
          validateStatus={errors.name ? 'error' : ''}
          help={errors.name ? errors.name.message : ''}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder="Enter Catalog Name"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Blurb is required' }}
            render={({ field }) => (
              <TextArea
                {...field}
                placeholder="Enter your description.."
                rows={8}
              />
            )}
          />
        </Form.Item>

        <Row justify={'end'}>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={!isDirty}>Add Catalog</Button>
        </Form.Item>
</Row>
        {isError && <p>Failed to add catalog. Please try again.</p>}
        {isSuccess && <p>Catalog added successfully!</p>}
      </Form>
    </div>
  );
};

export default AddCatalog;