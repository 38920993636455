import React, { useEffect, useState } from 'react';
import '../styles/EditGroup.scss'; // Update with relevant styles
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetGroupsQuery,
  useEditGroupsMutation,
  useGetGroupByIdQuery,

} from '../services/admin/inventoryApi'; // Update with correct API hooks
import { Campaign, Group, User } from '../types.ts'; // Update with correct types
import {
  Form,
  Input,
  Button,
  Typography,
  Drawer,
  List,
  Checkbox,
  message,
  Switch,
  Row,
  Spin,
  Flex,
  DatePicker,
  Divider,
} from 'antd';
import { DownOutlined, CopyOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import CampaignsTable from './CampaignsTable';
import AddTags from './AddTags';
import CurrencyInput from 'react-currency-input-field';
import { convertUTCDateToLocalDate } from '../utils';
import dayjs from 'dayjs';
import { usePlacesWidget } from 'react-google-autocomplete';
import { setGroupId } from '../slices/admin/adminUserSlice';
import { RootState } from '../store';

const { Title, Text } = Typography;

const EditGroup: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [inviteLink, setInviteLink] = useState<string>('');
  const [address, setAddress] = useState({});
  const [tags, setTags] = useState<string[]>([]);
  const {
    data: groupsData,
    error: groupsError,
    isLoading: groupsLoading,
  } = useGetGroupByIdQuery({ group_id: parseInt(id || "0") });
 
  const [updateGroup, { isLoading: isUpdating, isError, isSuccess }] =
    useEditGroupsMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_ADDRESS_KEY,
    onPlaceSelected: (place: any) => handlePlaceSelected(place),
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'usa' },
    },
  });
  const handlePlaceSelected = (place: any) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
    };

    for (const component of addressComponents) {
      const addressType = component.types[0];
      if (addressType === 'street_number') {
        newAddress.street1 += component.long_name + ' ';
      } else if (addressType === 'route') {
        newAddress.street1 += component.long_name;
      } else if (addressType === 'locality') {
        newAddress.city = component.long_name + ' ';
      } else if (addressType === 'sublocality_level_1') {
        newAddress.city += component.long_name;
      } else if (addressType === 'administrative_area_level_1') {
        newAddress.state = component.short_name;
      } else if (addressType === 'postal_code') {
        newAddress.postalCode = component.long_name;
      }
    }
    setValue("remittance_address_1",newAddress.street1)
    setValue("remittance_address_2",newAddress.street2)
    setValue("remittance_address_city",newAddress.city)
    setValue("remittance_address_state",newAddress.state)
    setValue("remittance_address_zip",newAddress.postalCode)
    setAddress(newAddress);
   
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Group>({
    defaultValues: {
      name: '',
      blurb: '',
      anticipated_end_date: undefined,
      anticipated_start_date: undefined,
      users: [],
      is_rep_group: false,
      is_signup_disabled: false,
      one_tag: false,
      remittance_is_electronic: false,
      anticipated_seller_count: 0,
      group_default_blurb: '',
      group_contacts_required: 0,
      anticipated_last_funds_raised: 0,
      remittance_address_1: "",
  remittance_address_2: "",
  remittance_address_city: "",
  remittance_address_state: "",
  remittance_address_zip: "",
  group_default_goal:0,
  fee_platform_base: 0,
  fee_platform_percentage: 0
    },
  });

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedUserNames, setSelectedUserNames] = useState<string>('');
  const user = useSelector((state: RootState) => state.adminUser.user);



  useEffect(() => {
    if (groupsData) {
      const group = groupsData;
      if (group) {
        setValue('name', group?.name);
        setValue('blurb', group.blurb);
        setValue('group_default_goal', group.group_default_goal);
        setValue('is_rep_group', group.is_rep_group);
        setValue('is_signup_disabled', group.is_signup_disabled);
        setValue('users', group.users ?? []);
        setValue('one_tag', group.one_tag ?? false);
        setValue('remittance_is_electronic', group.remittance_is_electronic ?? false);
        setValue('anticipated_seller_count', group.anticipated_seller_count ?? 0);
        setValue('group_default_blurb', group.group_default_blurb ?? '');
        setValue('group_contacts_required', group.group_contacts_required ?? 0);
        setValue('anticipated_last_funds_raised', group.anticipated_last_funds_raised ?? 0);
        setValue('remittance_address_1', group.remittance_address_1 ?? "");
        setValue('remittance_address_2', group.remittance_address_2 ?? "");
        setValue('remittance_address_city', group.remittance_address_city ?? '');
        setValue('remittance_address_state', group.remittance_address_state ?? "");
        setValue('fee_platform_base', group.fee_platform_base ?? process.env.REACT_APP_FEE_PLATFORM_BASE);
        setValue('fee_platform_percentage', group.fee_platform_percentage ?? process.env.REACT_APP_FEE_PLATFORM_PERCENTAGE);
        setValue('remittance_address_zip', group.remittance_address_zip ?? "");
        const targetDate =group?.anticipated_start_date?convertUTCDateToLocalDate(new Date(group?.anticipated_start_date) as Date):null;
        const targetEndDate =group?.anticipated_end_date?convertUTCDateToLocalDate(new Date(group?.anticipated_end_date) as Date):null;
        //@ts-ignore
        setValue('anticipated_start_date',targetDate? dayjs(targetDate) as unknown:null);
        //@ts-ignore
        setValue('anticipated_end_date',targetEndDate? dayjs(targetEndDate) as unknown:null);
        setSelectedUsers(group.users ?? []);
        setTags(group?.tags?.map((tag)=>tag.name));
        setSelectedUserNames(
          group.users.map((user) => user.full_name).join(', ')
        );
        setInviteLink(`${process.env.REACT_APP_BASE_URL_FE}signup/${group.id}`);
      }
    }
  }, [groupsData, id, setValue]);

  useEffect(() => {
    setValue('users', selectedUsers);
  }, [selectedUsers, setValue]);

  const onSubmit = async (data: Group) => {
    const updatedData = {
      ...data,
      users: selectedUsers.map((user) => user.id),
    
    };
    try {
      await updateGroup({ groupData:updatedData, id: parseInt(id || '0'), tag_names:tags??[] }).unwrap();
      console.log('Group updated successfully:', updatedData);
      message.success('Group updated successfully!');
      // navigate(-1);
    } catch (error) {
      console.error('Failed to update group:', error);
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleUserToggle = (user: User) => {
    setSelectedUsers((prevSelectedUsers) => {
      const updatedSelectedUsers = prevSelectedUsers.some(
        (selectedUser) => selectedUser.id === user.id
      )
        ? prevSelectedUsers.filter(
            (selectedUser) => selectedUser.id !== user.id
          )
        : [...prevSelectedUsers, user];

      setSelectedUserNames(
        updatedSelectedUsers.map((user) => user.full_name).join(', ')
      );

      return updatedSelectedUsers;
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    message.success('Invite link copied to clipboard');
  };

  const handleCampaignRowClick = (record: Campaign) => {
    if (!user) return null;
    if (user?.scope === 'internal_admin' || user?.scope?.includes('rep'))
      navigate(`/edit-campaign/${record.id}`);
  };

  return (
    <>
      <div className="edit-group-container">
        <Title level={2} className="editGroup">
          Edit Group
        </Title>
        <div className="edit-campaign-container" >
     <Title level={2} className="editProduct">Quick Links</Title>
     <div  style={{
      padding:20,
   

      
     }}>
       <Button type="primary"  onClick={()=>{
        dispatch(setGroupId(id??""));
       
       dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
       navigate(`/dashboard/users`, { state: { route:"prevRouteGroup" } })
       }} style={{ marginRight: '8px' }}>
       View Users
      </Button>
      </div>
    
     </div>
        <Spin spinning={groupsLoading}>
          <Form
            onFinish={handleSubmit(onSubmit)}
            layout="vertical"
            className="edit-group-form"
          
          >
            <div style={{ maxWidth: 800 }}>
              <Form.Item
                label="Group Name"
                validateStatus={errors.name ? 'error' : ''}
                help={errors.name ? errors.name.message : ''}
              >
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      placeholder="Enter Group Name"
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Blurb"
                validateStatus={errors.blurb ? 'error' : ''}
                help={errors.blurb ? errors.blurb.message : ''}
              >
                <Controller
                  name="blurb"
                  control={control}
                  rules={{ required: 'Blurb is required' }}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      rows={8}
                      placeholder="Enter your description..."
                    />
                  )}
                />
              </Form.Item>
              <Form.Item label="Campaign Seller Invite Link">
                <Input
                  value={inviteLink}
                  readOnly
                  addonAfter={
                    <Button icon={<CopyOutlined />} onClick={copyToClipboard}>
                      Copy
                    </Button>
                  }
                />
              </Form.Item>
              <Divider />
              <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Remittance address 1"
          validateStatus={errors.remittance_address_1 ? 'error' : ''}
          help={errors.remittance_address_1 ? errors.remittance_address_1.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_1"
            control={control}
            render={({ field }) => (
           
              <Input type="text"  placeholder='Enter address 1'  
              {...field}
              ref={(inputRef) => {
                // Forward the ref to the native input inside Ant Design's Input
                if (inputRef) {
                  (ref as React.MutableRefObject<HTMLInputElement | null>).current = inputRef.input;
                }
              }}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Remittance address 2"
          validateStatus={errors.remittance_address_2 ? 'error' : ''}
          help={errors.remittance_address_2 ? errors.remittance_address_2.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_2"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter address 2'/>
            )}
          />
        </Form.Item>
      </Flex>
      <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Remittance address city"
          validateStatus={errors.remittance_address_city ? 'error' : ''}
          help={errors.remittance_address_city ? errors.remittance_address_city.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_city"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter city'/>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Remittance address state"
          validateStatus={errors.remittance_address_state ? 'error' : ''}
          help={errors.remittance_address_state ? errors.remittance_address_state.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_state"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter address state'/>
            )}
          />
        </Form.Item>
      </Flex>
      <Flex justify="space-between" align="center" className='flexRow'>
      <Form.Item
                label=""
                validateStatus={errors.remittance_is_electronic ? 'error' : ''}
                help={
                  errors.remittance_is_electronic
                    ? errors.remittance_is_electronic.message
                    : ''
                }
                className="repGroup"
              >
                <Controller
                  name="remittance_is_electronic"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                      <Text
                        style={{
                          borderBottom: 'none',
                          color: '#1d2026',
                        }}
                      >
                       Remittance is Electronic
                      </Text>
                    </div>
                  )}
                />
              </Form.Item>
             
              <Form.Item
          label="Remittance address zip"
          validateStatus={errors.remittance_address_zip ? 'error' : ''}
          help={errors.remittance_address_zip ? errors.remittance_address_zip.message : ''}
          style={{width:350}}
        >
          <Controller
            name="remittance_address_zip"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} placeholder='Enter address zip'/>
            )}
          />
        </Form.Item>
        </Flex>
              <Divider />
   
              <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Anticipated seller count"
          validateStatus={errors.anticipated_seller_count ? 'error' : ''}
          help={errors.anticipated_seller_count ? errors.anticipated_seller_count.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_seller_count"
            control={control}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter seller count'/>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Anticipated Last Funds Raised"
          validateStatus={errors.anticipated_last_funds_raised ? 'error' : ''}
          help={errors.anticipated_last_funds_raised ? errors.anticipated_last_funds_raised.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_last_funds_raised"
            control={control}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
               placeholder="Please enter Goal"
               defaultValue={((groupsData?.anticipated_last_funds_raised)??0)/100}
               value={field.value/100}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>
      </Flex>
      <Flex justify="space-between" align="center" className='flexRow'>
        <Form.Item
          label="Anticipated start date"
          validateStatus={errors.anticipated_start_date ? 'error' : ''}
          help={errors.anticipated_start_date ? errors.anticipated_start_date.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_start_date"
            control={control}
            render={({ field }) => (
              <DatePicker
              {...field} 
              style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
              showTime 
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select End Date and Time"
            />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Anticipated end date"
          validateStatus={errors.anticipated_end_date ? 'error' : ''}
          help={errors.anticipated_end_date ? errors.anticipated_end_date.message : ''}
          style={{width:350}}
        >
          <Controller
            name="anticipated_end_date"
            control={control}
            render={({ field }) => (
              <DatePicker
              {...field} 
              style={{ width: '100%' ,padding: 16,borderRadius: 0}} 
              showTime 
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select End Date and Time"
            />
            )}
          />
        </Form.Item>
      </Flex>
    
      <Divider />
      <Flex justify="space-between" align="center" className='flexRow'>
      <Form.Item
         label="Group default goal"
         validateStatus={errors.group_default_goal ? 'error' : ''}
         help={errors.group_default_goal ? errors.group_default_goal.message : ''}
         style={{width:350}}
       >
         <Controller
           name="group_default_goal"
           control={control}
           render={({ field }) => (
             <CurrencyInput
             className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
              placeholder="Please enter Goal"
              defaultValue={((groupsData?.group_default_goal)??0)/100}
              value={field.value/100}
              decimalsLimit={2}
              onValueChange={(value) => {
                const centsValue = value ? parseFloat(value) * 100 : 0;
                field.onChange( centsValue );
              }}
              prefix="$"
            />
           )}
         />
       </Form.Item>
       
        <Form.Item
          label="Group Contacts Required"
          validateStatus={errors.group_contacts_required ? 'error' : ''}
          help={errors.group_contacts_required ? errors.group_contacts_required.message : ''}
          style={{width:350}}
        >
          <Controller
            name="group_contacts_required"
            control={control}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter contacts required count'/>
            )}
          />
        </Form.Item>
      </Flex>
     
      <Form.Item
          label="Group default blurb"
          validateStatus={errors.group_default_blurb ? 'error' : ''}
          help={errors.group_default_blurb ? errors.group_default_blurb.message : ''}
         
        >
          <Controller
            name="group_default_blurb"
            control={control}
            render={({ field }) => (
              <TextArea
              {...field}
              rows={8}
              placeholder="Enter your description..."
            />
            )}
          />
        </Form.Item>
      
  
     {user?.scope === 'internal_admin' && <> <Divider />
      <Flex justify="space-between" align="center" className='flexRow'>
      <Form.Item
          label="Platform Base fee percentage"
          validateStatus={errors.fee_platform_percentage ? 'error' : ''}
          help={errors.fee_platform_percentage ? errors.fee_platform_percentage.message : ''}
          style={{width:350}}
        >
          <Controller
            name="fee_platform_percentage"
            control={control}
            render={({ field }) => (
              <Input type="number" {...field} placeholder='Enter base fee'/>
            )}
          />
        </Form.Item>
  <Form.Item
    label="Platform base amount"
    validateStatus={errors.fee_platform_base ? 'error' : ''}
    help={errors.fee_platform_base ? errors.fee_platform_base.message : ''}
    style={{width:350}}
  >
    <Controller
      name="fee_platform_base"
      control={control}
      render={({ field }) => (
        <CurrencyInput
        className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
         placeholder="Please enter Goal"
         defaultValue={0/100}
         value={field.value/100}
         decimalsLimit={2}
         onValueChange={(value) => {
           const centsValue = value ? parseFloat(value) * 100 : 0;
           field.onChange( centsValue );
         }}
         prefix="$"
       />
      )}
    />
  </Form.Item>
</Flex>
</>}
      <Divider />
           
              <Form.Item
                label="Users"
                validateStatus={errors.users ? 'error' : ''}
                help={errors.users ? errors.users.message : ''}
              
              >
                <Input
                onClick={handleDrawerOpen}
                  value={
                    selectedUserNames.length > 40
                      ? `${selectedUserNames.slice(0, 40)}...`
                      : selectedUserNames
                  }
                  placeholder="Select Users"
                  readOnly
                  style={{ marginTop: '10px' }}
                  addonAfter={<DownOutlined onClick={handleDrawerOpen} />}
                />
              </Form.Item>
              <Form.Item label="Tags">
              <AddTags tags={tags} setTags={setTags} />
              </Form.Item>
              {user?.scope === 'internal_admin' && (
                <Form.Item
                  label=""
                  validateStatus={errors.is_rep_group ? 'error' : ''}
                  help={errors.is_rep_group ? errors.is_rep_group.message : ''}
                  className="repGroup"
                >
                  <Controller
                    name="is_rep_group"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                        />
                        <Text
                          style={{
                            borderBottom: 'none',
                            color: '#1d2026',
                          }}
                        >
                         Is Fundraising Company
                        </Text>
                      </div>
                    )}
                  />
                </Form.Item>
              )}

              <Form.Item
                label=""
                validateStatus={errors.is_signup_disabled ? 'error' : ''}
                help={
                  errors.is_signup_disabled
                    ? errors.is_signup_disabled.message
                    : ''
                }
                className="repGroup"
              >
                <Controller
                  name="is_signup_disabled"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                      <Text
                        style={{
                          borderBottom: 'none',
                          color: '#1d2026',
                        }}
                      >
                        Disable Signup
                      </Text>
                    </div>
                  )}
                />
              </Form.Item>
           
              <Form.Item
                label=""
                validateStatus={errors.one_tag ? 'error' : ''}
                help={
                  errors.one_tag
                    ? errors.one_tag.message
                    : ''
                }
                className="repGroup"
              >
                <Controller
                  name="one_tag"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                      <Text
                        style={{
                          borderBottom: 'none',
                          color: '#1d2026',
                        }}
                      >
                        Allow Single Tag
                      </Text>
                    </div>
                  )}
                />
              </Form.Item>
            </div>
            <Row justify={'end'}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={groupsLoading || isUpdating}
                >
                  Save Change
                </Button>
              </Form.Item>
            </Row>
            {isError && <p>Failed to update group. Please try again.</p>}
            {isSuccess && <p>Group updated successfully!</p>}
          </Form>
      
          </Spin>
        <Drawer
          title="Users"
          width={400}
          onClose={handleDrawerClose}
          visible={isDrawerVisible}
        >
          <List
            dataSource={
             selectedUsers ?? []
            }
            renderItem={(user: User) => (
              <List.Item
                key={user.id}
                onClick={() => {
                  navigate(`/edit-user/${user.id}`);
                  dispatch(
                    setActiveTab(
                      `tab6` as
                        | 'tab1'
                        | 'tab2'
                        | 'tab3'
                        | 'tab4'
                        | 'tab5'
                        | 'tab6'
                        | 'tab7'
                        | 'tab8'
                        | 'tab9'
                        | 'tab11'
                    )
                  );
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                {/* <Checkbox
                checked={selectedUsers.some(selectedUser => selectedUser.id === user.id)}
                onChange={() => handleUserToggle(user)}
              > */}
                {user.full_name} - {user.email}
                {/* </Checkbox> */}
              </List.Item>
            )}
          />
        </Drawer>
      </div>
      <div
        style={{
          height: 30,
        }}
      ></div>
      <CampaignsTable
        handleRowClick={handleCampaignRowClick}
        groupId={parseInt(id || '0')}
      />
    </>
  );
};

export default EditGroup;
