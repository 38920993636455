import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserResponse } from '../../types.ts';

interface UserState {
  token: string | null;
  email: string | null;
  isActive: boolean;
  isSuperuser: boolean;
  fullName: string | null;
  scope: string;
  id: number | null;
  groupId: string | null;  // Add groupId to state
  campaignId: string | null;  // Add groupId to state
  userId: string | null;  // Add groupId to state
  user: Partial<UserResponse>;
}

const initialState: UserState = {
  token: null,
  email: null,
  isActive: false,
  isSuperuser: false,
  fullName: null,
  scope: '',
  id: null,
  groupId: null,  // Initialize groupId to null
  campaignId:  null,  // Add groupId to state
  userId: null,  // Add groupId to state
  user:{}
};

interface SignInPayload {
  token: string;
  email: string;
}

interface UserDetailsPayload {
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  full_name: string | null;
  scope: string;
  id: number;
  user: UserResponse
}

const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<SignInPayload>) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
    },
    signOut: (state) => {
      state.token = null;
      state.email = null;
      state.isActive = false;
      state.isSuperuser = false;
      state.fullName = null;
      state.scope = '';
      state.id = null;
      state.groupId = null;
      state.campaignId = null;
      state.userId = null;
    },
    setUserDetails: (state, action: PayloadAction<Partial<UserResponse>>) => {
     state.user={ ...action.payload}
    },
    setGroupId: (state, action: PayloadAction<string | null>) => {
      state.groupId = action.payload;  // Update groupId in the state
    },
    setCampaignId: (state, action: PayloadAction<string | null>) => {
      state.campaignId = action.payload;  // Update groupId in the state
    },
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;  // Update groupId in the state
    },
  },
});

export const { signIn, signOut, setUserDetails, setGroupId, setCampaignId, setUserId } = adminUserSlice.actions;
export default adminUserSlice.reducer;
