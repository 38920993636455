import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Button, Pagination, Row, Col, Card, Flex, Grid } from 'antd';
import {
  useGetCampaignsQuery,
  useDownloadReportMutation,
  useInviteUsersMutation,
  useGetInviteStudentsQuery,
  useInviteStudentsMutation,
} from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts';
import {
  CopyOutlined,
  DownloadOutlined,
  EyeOutlined,
  StepBackwardFilled,
  UploadOutlined,
} from '@ant-design/icons';
import CSVUploader from './CSVUploader';

import ContactsModal from './ContactsModal';
import LeaderBoardModal from './LeaderBoardModal';
// @ts-ignore
import { CSVLink, CSVDownload } from 'react-csv';

import { Typography } from 'antd';
import CampaignCard from './CampaignCard';
// @ts-ignore
import SvgIcon from '../assets/svg/Man';
import { convertUTCDateToLocalDate, formatWithCommas } from '../utils';
import '../styles/StudentCampaign.scss';
import Title from 'antd/es/typography/Title';
import Meta from 'antd/es/card/Meta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCashRegister,
  faDollarSign,
  faHandHoldingDollar,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FaArrowAltCircleLeft, FaStepBackward } from 'react-icons/fa';
import OrdersTable from './OrdersTable';
import Loader from './Loader';
import { useLocation, useParams } from 'react-router-dom';
import DashboardOrdersTable from './DashboardOrdersTable';
import ShareButtons from './ShareButtons';
import ShareButtonStatic from './ShareButtonStatic';
import CustomCSVUploader from './CustomCSVUploader';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface ParsedData {
  email_address: string;
  name: string;
}
const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

const CampaignDetails: React.FC<{ isMobile?: boolean }> = ({ isMobile=false }) => {

  const screens = useBreakpoint();

    const location = useLocation();
    const { campaignId } = location.state || {};

  const { data: studentData, isLoading: isLoadingStudents } =
    useGetInviteStudentsQuery({
      campaignId: campaignId ? campaignId : '',
      skip: 0,
      limit: 10,
    });
  const { currentPage, pageSize, onPageChange } = usePagination();
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const [downloadReport] = useDownloadReportMutation();
  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [leaderBoardModalVisible, setLeaderBoardModalVisible] = useState(false);

  const [selectedContactsCampaignId, setSelectedContactsCampaignId] = useState<
    string | null
  >(null);


  const [inviteStudents, { isLoading: isUploadingCSV }] = useInviteStudentsMutation();
  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);

  const handleDataParsed = async (data: ParsedData[], id: string) => {
    console.log('Parsed Data:', data);
    await inviteStudents({ campaignId: id ?? '0', data });
    message.success('CSV file parsed successfully!');
  };

  const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link);
    message.success('Link copied to clipboard');
  };
  const handleNavigateToCampaign = () => {
    window.open(`${process.env.REACT_APP_BASE_URL_FE}raise/${data?.find((campaign) => campaign.id === campaignId)?.campaign_link}/${user?.id}`, '_blank');
  };
  
  const openContactsModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setContactsModalVisible(true);
  };

  const closeContactsModal = () => {
    setContactsModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const openLeaderBoardModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setLeaderBoardModalVisible(true);
  };

  const closeLeaderBoardModal = () => {
    setLeaderBoardModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const columns = [
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: 'Invite Contacts',
      dataIndex: 'orderReport',
      key: 'orderReport',
      render: (text: string, record: Campaign) => (
        <div>
          <CSVUploader
            onDataParsed={(data) => handleDataParsed(data, record.id)}
            loading={isUploadingCSV}
          />
        </div>
      ),
    },
    {
      title: 'View Campaign',
      dataIndex: 'userReport',
      key: 'userReport',
      render: (text: string, record: Campaign) => (
        <Button
          icon={<CopyOutlined />}
          onClick={() =>
            copyToClipboard(
              `${process.env.REACT_APP_BASE_URL_FE}raise/${record.id}/${user?.id}`
            )
          }
        >
          Copy link
        </Button>
      ),
    },
    {
      title: 'View Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => openContactsModal(record.id)}>
          View Contacts
        </Button>
      ),
    },
    {
      title: 'Is Closed',
      dataIndex: 'is_closed',
      key: 'is_closed',
      sorter: (a: any, b: any) => Number(a.is_closed) - Number(b.is_closed),
      render: (text: boolean) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'View Leader Board',
      dataIndex: 'View Leader Board',
      key: 'View Leader Board',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => openLeaderBoardModal(record.id)}>
          View Leader Board
        </Button>
      ),
    },
  ];
console.log(user,'jk')
  return (
    <>
    
        <div style={{ marginTop: '60px' }}>
        <Flex justify="space-between" align={screens.md?
          "center":"normal"}  vertical={screens.md?false:true}   >
        <Title level={screens.md?4:2}>
            Campaigns -{' '}
            {data?.find((campaign) => campaign.id === campaignId)?.title}
          </Title>
          <Button
                type="primary"
                className="button"
                onClick={handleNavigateToCampaign}
              >
                Open  Store
              </Button>
          </Flex>
         
          <div className="campaign-detail">
            {/* <div className="button-container">
              <Button
                type="primary"
                className="button"
                onClick={handleNavigateToCampaign}
              >
                Open My Store
              </Button>
              <Button
                type="primary"
                className="button"
                onClick={() =>
                  copyToClipboard(
                    `${process.env.REACT_APP_BASE_URL_FE}raise/${data?.find((campaign) => campaign.id === campaignId)?.campaign_link}/${user?.id}`
                  )
                }
              >
                Share My Store
              </Button>
            </div> */}
            <div className="card-container">
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faSackDollar}
                          size={"2xl"}
                          color="#FFBB38"
                        />
                      </div>
                    </div>
                  }
                  title={`$${formatWithCommas(
                    data?.find((campaign) => campaign.id === campaignId)?.me_amount)}`}
                  description="Raised by Me"
                />
              </Card>
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container purple">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faHandHoldingDollar}
                          size={"2xl"}
                          color="#396AFF"
                        />
                      </div>
                    </div>
                  }
                  title={ data?.find((campaign) => campaign.id === campaignId)?.me_count?data?.find((campaign) => campaign.id === campaignId)?.me_count:'0'}
                  description="Sold By Me"
                />
              </Card>
            </div>
          </div>
          <Title level={4}>Share On</Title>
          <ShareButtonStatic
          campaignId={campaignId ?? ''}
          userId={user?.id ?? 0}
          showEmail={false}
            schoolName={data?.find((campaign) => campaign.id === campaignId)?.title ?? ''}
            blurb={ data?.find((campaign) => campaign.id === campaignId)?.blurb ?? ''}
           />
         
          <Title level={4}>Contacts</Title>
          <Card className="campaign-contacts">
            <Meta
              avatar={<Flex  align="start" gap={10}><Meta title={studentData?.count ?studentData?.count: "0"} description="Count" />
             {!!data?.find((campaign) => campaign.id === campaignId)?.group.group_contacts_required&& <Meta title={data?.find((campaign) => campaign.id === campaignId)?.group.group_contacts_required ?data?.find((campaign) => campaign.id === campaignId)?.group.group_contacts_required: "0"} description="Required Conatcts Count" />}
              </Flex>}
              title={
                <>
                 <Button
                   
                   onClick={() => openContactsModal(campaignId)}
                 >
                   <EyeOutlined />
                   View Contact
                 </Button>
                  <CustomCSVUploader
                    button={
                      <Button className='csv-button'  >
                        <UploadOutlined />
                        Upload CSV
                      </Button>
                    }
                    onDataParsed={(data) => handleDataParsed(data, campaignId)}
                    loading={isUploadingCSV}
                  />
                 
                </>
              }
              description={
                <Paragraph style={{ marginBottom: 0 , marginTop: 10}}>
                  Not sure what to include?
                  <CSVLink 
                  filename="Sample.csv"
         data ={`name,email
John Doe,jdoe@example.com`}
         >
          {' '}Download our sample CSV
         </CSVLink>
                  
                </Paragraph>
              }
            />
          </Card>
          <Title level={3}>Recent Orders</Title>
          <DashboardOrdersTable user_id={user?.id} campaign_id={campaignId} />
        </div>
   
      {selectedContactsCampaignId && (
        <ContactsModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId}
          visible={contactsModalVisible}
          onClose={closeContactsModal}
        />
      )}
      {leaderBoardModalVisible && (
        <LeaderBoardModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId ?? ''}
          visible={leaderBoardModalVisible}
          onClose={closeLeaderBoardModal}
        />
      )}
      {/* <Paragraph>
        Not sure what to include?
        <CSVLink 
         data ={`name,email
John Doe,jdoe@example.com`}
         >
          {''}Download our sample CSV
         </CSVLink>
        
         
         ;



       </Paragraph> */}
    </>
  );
};

export default CampaignDetails;
