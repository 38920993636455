import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, List } from 'antd';
import '../styles/DonationOptions.scss';
import CurrencyInput from 'react-currency-input-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setDonationAmount } from '../slices/campaign/donationSlice';
import { useGetCampaignForUserQuery } from '../services/campaign';
import NotFound from './NotFound';
import ShareButtons from './ShareButtons';
import MessageCarousel from './MessageCarousel';
import { FaShoppingCart } from 'react-icons/fa';

const DonationOptions: React.FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customAmount, setCustomAmount] = useState<number>(0);

  const { campaign_id ,user_id} = useParams<{ campaign_id: string,user_id:string }>();
  const selectedDonation = useSelector(
    (state: RootState) => state.donation.amount
  );
  const { data, isLoading, isError } = useGetCampaignForUserQuery(
    campaign_id || '0',
    {
      skip: !campaign_id,
    }
  );
 
  const amounts = [10,20, 25, 50, 75, 100, 0];
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const handleAmountClick = (amount: number) => {
    dispatch(setDonationAmount(amount * 100)); // Assuming the amount is stored in cents
  };

  const handleCustomAmountSave = () => {
    dispatch(setDonationAmount(customAmount));
    setIsModalOpen(false);
  };
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isError) return <NotFound />;
  if (isLoading) return <></>;

  return (
    <>
     <div
    
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      
      
    }}
  >
   {(data?.donations_enabled&&!data?.is_closed)&& <List
   
    footer={
      totalItems >0&&  <div style={{display: "flex",justifyContent: "center", margin: "20px 0px"}}>

<Button type='primary' onClick={()=>{
 navigate(`/raise/${campaign_id}/${user_id}/checkout`)
}} style={{padding:"10px 20px", fontSize: "14px"}}> 
<FaShoppingCart className="cart-icon" />
Checkout ({totalItems} items)</Button>
    </div>
   
  
  }
      itemLayout="horizontal"
      
      
    
      
      grid={{
        gutter: 4,
        xs: 3,
        
        
      
        
      }}
      
      // @ts-ignore
      
      
      header={<h2 style={{ textAlign: 'center', }}>Donation</h2>}
      dataSource={amounts}
      renderItem={(item, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: '15px',
            
           
          }}
          
        >

          
          <div
            key={item}
            className={
              // index !== 5
              //   ?
                 `donation-button ${
                    selectedDonation === item * 100 ? 'selected' : ''
                  }`
                // : 'donation-button custom'
            }
            onClick={() =>
              // index !== 5 ?
               handleAmountClick(item) 
              //  : setIsModalOpen(true)
            }
          >
            <div className="donation-info">
              <FontAwesomeIcon
                icon={
                  // index !== 5
                  //   ?
                     selectedDonation === item * 100
                      ? faCheck
                      : faMoneyBill1Wave
                    // : amounts.includes(selectedDonation / 100)
                    // ? faMoneyBill1Wave
                    // : faCheck
                }
                className="donation-icon"
                color={'white'}
              />
              {/* {index !== 5 ? ( */}
                <span className="donation-amount">${item}</span>
              {/* ) : (
                <span className="donation-amount">
                  {amounts.includes(selectedDonation / 100)
                    ? 'Other'
                    : `$ ${selectedDonation / 100}`}
                </span>
              )} */}
            </div>
          </div>
        </div>
      )}
    />}

    <Modal
      title="Enter Custom Amount"
      visible={isModalOpen}
      onOk={handleCustomAmountSave}
      onCancel={() => setIsModalOpen(false)}
    >
      <CurrencyInput
        placeholder="Please enter Amount"
        defaultValue={customAmount}
        decimalsLimit={2}
        onValueChange={(value) => {
          const centsValue = value ? parseFloat(value) * 100 : 0;
          setCustomAmount(Number(centsValue));
        }}
        prefix="$"
        className="donation-input"
      />
    </Modal>

    <ShareButtons schoolName={data?.title ?? ''} blurb={data?.blurb ?? ''} />
  </div>
  
  <MessageCarousel campaignId={campaign_id ?? ''} />
    </>
   
  );
};

export default DonationOptions;
