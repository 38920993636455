import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import store from './store';
import App from './App';
import { PostHogProvider} from 'posthog-js/react'


import './styles/LandingPage.scss'; // Import the new styles
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}
ReactDOM.render(
<Provider store={store}>
<PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY || 'default-api-key'}
      options={options}
    >
    <App />
    </PostHogProvider>
    </Provider>,
  document.getElementById('root')
);