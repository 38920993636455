import React from 'react';

interface SvgIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const Hand: React.FC<SvgIconProps> = ({ width = 24, height = 24, fill = 'currentColor', className }) => (
    <svg width="100%" height="195" viewBox="0 0 101 195" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.2344 65.7609C69.2791 65.5341 69.4394 65.3475 69.6562 65.2692C71.2929 64.6788 72.967 64.1989 74.6582 63.8353C74.739 63.8168 74.8218 63.7971 74.9298 63.7717C76.3232 63.4416 76.804 63.3587 77.5122 63.4028C78.5123 63.465 79.3518 63.8942 80.0597 64.7706C81.0752 66.0304 81.7482 67.7056 82.0905 69.5809C82.2872 70.6874 82.0754 72.2665 81.5167 73.162C80.892 74.1777 79.8761 75.0791 78.4088 76.0618C78.1467 76.2373 77.8735 76.4137 77.5575 76.6118C77.434 76.6898 77.3075 76.7684 77.1505 76.8655C77.095 76.9001 76.8342 77.0614 76.7639 77.1051C76.1777 77.4681 75.6837 77.7982 74.7124 78.4644C74.6063 78.5374 74.6063 78.5374 74.4999 78.6105C73.0258 79.6215 72.2483 80.1221 71.2814 80.6431C69.7376 81.433 68.277 82.3759 66.9215 83.458C66.4429 83.8401 65.7492 83.4169 65.8678 82.8156L69.2344 65.7609Z" fill="#FBD2AE"/>
    <path d="M73.4662 104.741C77.7844 103.989 85.8339 101.506 87.3819 99.8989C88.8951 98.3478 89.4568 96.2234 89.4344 93.3384C89.4279 92.5126 89.3845 91.7741 89.2817 90.4777L89.2492 90.066C89.0009 87.3386 88.4855 84.6501 87.7097 82.032C86.8097 78.8652 85.4086 78.1428 81.5397 78.3531C77.5361 78.6174 73.5648 79.172 69.6493 80.0122C69.3093 80.0853 69.085 80.4108 69.1375 80.755L72.7046 104.191C72.7601 104.556 73.1036 104.804 73.4662 104.741Z" fill="#FBD2AE"/>
    <path d="M87.7199 89.0901C86.7247 89.2221 85.5728 89.361 84.1966 89.517C83.176 89.6328 82.1391 89.7464 80.554 89.9172C76.1284 90.3938 75.2373 90.4922 73.5858 90.6956C73.2258 90.74 72.9696 91.0684 73.014 91.4292C73.0583 91.7899 73.3861 92.0466 73.7461 92.0021C75.3877 91.8001 76.2778 91.702 80.6946 91.2261C82.282 91.055 83.3209 90.9414 84.3444 90.8252C85.7281 90.6682 86.8876 90.5284 87.892 90.3954C88.2517 90.3477 88.5046 90.0169 88.4573 89.6565C88.4097 89.2962 88.0796 89.0427 87.7199 89.0901Z" fill="#FCB7A0"/>
    <path d="M64.4259 96.6891C62.0032 93.6638 59.5811 91.4479 57.0491 90.1357C53.4985 88.2914 49.4168 87.1033 45.6599 86.9664C41.1781 86.8028 37.3496 88.1113 34.6169 91.0661C34.0194 91.7135 33.5428 92.5274 33.0932 93.6207L31.1908 87.4199C31.0528 86.9703 30.5023 86.8067 30.1417 87.1082C28.8837 88.1604 27.0647 90.7179 24.8049 94.4172C23.2845 96.9066 21.8357 99.4569 20.3728 102.13C19.998 102.816 19.6127 103.584 19.216 104.432C18.2398 106.518 17.2143 109.041 16.0838 112.075C15.5474 113.516 15.0075 115.016 14.3699 116.825C14.1062 117.572 13.1185 120.395 12.8935 121.033C9.73509 129.976 7.83695 139.778 5.73089 155.345C5.31769 158.404 4.98202 162.945 4.714 168.583C4.53335 172.383 4.39408 184.681 4.29127 188.75C4.25514 190.175 4.22624 191.497 4.2039 192.684C4.19602 193.1 4.18945 193.469 4.1842 193.787C4.18223 193.898 4.18124 193.963 4.1796 194.077C4.17829 194.16 4.17828 194.16 4.17796 194.178C4.1727 194.544 4.46798 194.845 4.83421 194.845L57.0356 194.901C57.3986 194.902 57.6932 194.607 57.6932 194.243C57.6932 180.236 58.4368 153.777 59.7546 147.59C60.8119 142.639 62.7438 136.564 64.9244 131.121C65.9479 128.56 67.1665 125.79 68.8081 122.231C69.3008 122.155 69.7902 122.058 70.2753 121.942C72.6142 121.363 73.302 121.182 74.5055 120.792C79.1699 119.279 81.97 117.132 82.4686 113.651C82.8745 110.821 81.5811 107.005 79.5696 105.072C78.1277 103.687 76.1244 103.325 73.4633 103.641C72.6697 103.735 71.8966 103.874 70.7772 104.107C70.6084 104.142 70.0483 104.26 69.9692 104.277C69.8569 104.3 69.7547 104.322 69.6598 104.341C67.6168 101.027 66.1535 98.8471 64.4259 96.6891Z" fill="#FBD2AE"/>
    <path d="M65.2286 110.069C61.2192 110.488 58.9055 113.773 60.0715 117.503C60.0811 117.533 60.5005 118.985 60.6601 119.468C61.0943 120.783 61.5512 121.684 62.1855 122.222C63.9289 123.703 65.6339 123.988 68.0224 123.399C68.3749 123.312 68.59 122.955 68.5033 122.602C68.4166 122.249 68.0605 122.033 67.7084 122.12C65.7026 122.615 64.4295 122.403 63.0352 121.218C62.641 120.883 62.2696 120.151 61.9073 119.055C61.7572 118.6 61.3426 117.165 61.3259 117.111C60.4141 114.194 62.1612 111.714 65.3652 111.378C65.7259 111.341 65.988 111.017 65.9502 110.655C65.9125 110.294 65.5896 110.031 65.2286 110.069Z" fill="#FCB7A0"/>
    <path d="M61.99 111.991L61.9881 111.991C58.5968 112.587 48.6971 113.086 44.2912 112.988C43.8409 112.978 43.3863 112.973 42.7373 112.969C42.6266 112.969 42.5382 112.968 42.3385 112.967C38.0266 112.944 36.1984 112.79 34.1899 112.038C31.4667 111.019 29.7117 108.988 28.953 105.629C28.1407 102.032 27.5604 98.1556 27.0368 93.2511C26.8414 91.4219 26.7399 90.3605 26.3858 86.5484C26.3135 85.7687 26.2623 85.2194 26.2104 84.6741C25.7486 79.793 25.3318 74.4038 24.7343 65.934C24.7908 66.7364 24.1707 57.8845 24.0048 55.5804C23.7743 52.3719 23.5765 49.7488 23.3847 47.3858C22.9081 41.4331 22.8375 35.4798 23.6317 33.0931C24.5983 30.1131 27.1977 27.9622 30.2846 27.5771C38.1314 26.3978 47.9926 25.506 53.2236 25.6151C53.6916 25.6249 54.1055 25.6291 55.0038 25.6351C57.6528 25.6528 58.8868 25.6845 60.4466 25.8125C65.3872 26.2182 68.8373 27.4878 70.8209 30.2173C71.9022 31.7081 72.5417 33.8612 72.9414 36.7633C73.0416 37.49 73.125 38.2493 73.2061 39.13C73.237 39.4677 73.2665 39.8073 73.303 40.2473C73.2964 40.1664 73.3703 41.0619 73.3897 41.29C73.414 41.5753 73.4354 41.8129 73.4564 42.0334C73.4722 42.2088 73.4722 42.2089 73.4883 42.3839C74.1481 49.61 74.1675 49.8183 74.4401 52.5177C74.626 54.3564 74.8093 56.0573 74.9982 57.6821C76.7153 72.2382 77.7447 84.5164 78.1293 96.4254C78.3047 101.8 77.5046 105.455 74.7232 107.848C73.4035 108.977 71.2544 109.883 68.4353 110.645C67.2745 110.959 66.0491 111.235 64.7195 111.495C64.2192 111.592 63.7272 111.683 63.1708 111.782C62.9425 111.823 62.1513 111.962 61.99 111.991Z" fill="#1A4E66"/>
    <path d="M60.531 109.243C56.6369 109.676 52.7197 109.867 48.8019 109.815C43.0835 109.69 38.2716 110.454 36.718 103.579C35.3352 97.4572 34.8261 89.5288 34.2316 83.2559C33.266 73.0534 32.4908 58.9015 31.6598 48.6463C31.4365 45.8818 30.7894 36.3802 31.8569 33.8888C32.9244 31.3974 35.5422 30.624 37.4242 30.2686C41.947 29.3931 47.4585 28.7119 52.6317 28.8205C57.3384 28.9192 65.0374 28.4288 68.1775 32.7699C70.0234 35.3172 70.1778 41.5374 70.4767 44.5488C70.9234 49.2321 71.3405 54.0602 71.9022 58.8126C73.2817 70.5093 74.3656 83.8253 74.7499 95.6734C74.8582 98.9645 74.6513 103.197 71.8562 105.599C69.2449 107.834 62.7875 108.966 60.531 109.243Z" fill="white"/>
    <path d="M29.3271 29.5668C27.7183 30.6448 27.2014 33.3919 27.2746 37.7286C27.293 38.8292 27.3455 39.9166 27.4418 41.4279C27.4553 41.6421 27.5131 42.5324 27.5272 42.7539C27.5571 43.2265 27.5771 43.577 27.5909 43.8844C27.7203 46.6963 27.9164 49.9582 28.2156 54.4578C28.2577 55.0914 28.3023 55.7542 28.3532 56.5082C28.3867 57.0048 28.5868 59.9573 28.6426 60.7867C28.7503 62.3842 28.8328 63.6368 28.9054 64.7818C29.5813 75.5543 30.6032 87.0463 31.9722 99.7788C32.3476 103.297 33.1319 106.592 34.3177 108.896C34.4842 109.219 34.8803 109.346 35.2029 109.179C35.5251 109.013 35.6518 108.616 35.4853 108.293C34.3886 106.162 33.6381 103.009 33.2788 99.6382C31.9114 86.9233 30.8912 75.4503 30.2166 64.6985C30.1437 63.5512 30.0612 62.2973 29.9535 60.6981C29.8976 59.8681 29.6976 56.9156 29.6641 56.4193C29.6132 55.6657 29.5685 55.0031 29.5265 54.3703C29.2279 49.8788 29.0325 46.6246 28.9034 43.8241C28.889 43.5079 28.8686 43.1504 28.8384 42.6709C28.8243 42.4478 28.7664 41.5569 28.753 41.3443C28.6581 39.8517 28.6062 38.7818 28.5881 37.7066C28.5234 33.852 28.9888 31.377 30.0576 30.6609C30.3591 30.4588 30.4399 30.0502 30.2383 29.748C30.0366 29.4458 29.6286 29.3646 29.3271 29.5668Z" fill="#0D323F"/>
    <path d="M20.9334 123.042C20.4358 123.031 20.1317 122.49 20.3787 122.058C21.231 120.564 22.201 118.984 23.4028 117.111C23.8964 116.341 24.4085 115.554 25.0894 114.514C25.3068 114.182 26.104 112.967 26.0406 113.064C26.4216 112.483 26.7054 112.049 26.981 111.626C27.6842 110.547 28.2586 109.652 28.7878 108.807C32.905 102.236 37.1913 96.9942 41.8124 93.3838C44.459 91.3163 47.5978 91.8488 49.5843 94.6644C50.6251 96.1368 50.6734 97.8058 49.982 99.7923C49.7104 100.573 49.3764 101.299 48.7841 102.463C48.0195 103.966 48.0218 103.962 47.8037 104.459C47.0118 106.265 46.503 109.375 46.3907 112.273C46.3437 113.511 46.4876 114.922 46.8013 116.528C47.0447 117.775 47.3731 119.07 47.8343 120.675C47.9305 121.011 48.0303 121.352 48.1565 121.779C48.1896 121.892 48.4058 122.621 48.4662 122.827C48.5923 123.254 48.2662 123.681 47.8208 123.671L20.9334 123.042Z" fill="#FBD2AE"/>
    <path d="M26.508 52.2942C26.3825 50.4512 26.2883 48.623 26.2268 46.8267C26.2143 46.4633 25.9102 46.179 25.5476 46.1915C25.1853 46.204 24.9012 46.5087 24.9137 46.8721C24.9757 48.6832 25.071 50.5262 25.1974 52.3837C25.2221 52.7464 25.5354 53.0206 25.8974 52.9956C26.2593 52.9709 26.533 52.6569 26.508 52.2942Z" fill="#0D323F"/>
    <path d="M27.1738 61.3587C27.0109 59.5972 26.8844 57.7272 26.7882 55.7236C26.7708 55.3606 26.4627 55.0802 26.1004 55.0976C25.7381 55.115 25.4582 55.4238 25.4757 55.7868C25.5729 57.8088 25.701 59.698 25.8655 61.4798C25.8987 61.8418 26.2186 62.1081 26.5799 62.0745C26.9412 62.0412 27.2069 61.7207 27.1738 61.3587Z" fill="#0D323F"/>
    <path d="M46.2462 113.636C46.461 115.893 46.9924 117.663 48.0563 120.222C48.1151 120.363 48.1745 120.505 48.255 120.696C48.3043 120.813 48.3043 120.813 48.3532 120.93C48.4018 121.046 48.4215 121.093 48.4501 121.161C48.6048 121.53 48.7172 121.802 48.8229 122.066C48.9576 122.404 49.3399 122.568 49.6769 122.433C50.0136 122.297 50.1771 121.914 50.0425 121.577C49.9334 121.304 49.8188 121.026 49.6615 120.651C49.6325 120.582 49.6128 120.535 49.5639 120.418C49.515 120.302 49.515 120.302 49.4657 120.185C49.3859 119.995 49.3271 119.855 49.2693 119.716C48.2514 117.268 47.7541 115.611 47.5541 113.511C47.5196 113.149 47.199 112.884 46.8377 112.919C46.4768 112.953 46.2117 113.274 46.2462 113.636Z" fill="#FCB7A0"/>
    <path d="M20.9574 121.089C21.792 119.627 22.7449 118.074 23.9273 116.231C24.4197 115.464 24.9308 114.678 25.612 113.638C25.8297 113.306 26.6246 112.095 26.5671 112.183C26.9498 111.599 27.2358 111.162 27.5131 110.736C28.2229 109.647 28.8036 108.743 29.339 107.888C29.5318 107.58 29.4391 107.174 29.1317 106.98C28.8246 106.787 28.4189 106.88 28.2261 107.188C27.6954 108.035 27.1189 108.934 26.4131 110.017C26.1365 110.441 25.8514 110.877 25.4691 111.46C25.5269 111.371 24.7317 112.583 24.5136 112.916C23.8304 113.958 23.317 114.748 22.8221 115.519C21.6285 117.38 20.6644 118.951 19.817 120.435C19.637 120.751 19.7461 121.153 20.0611 121.333C20.3761 121.514 20.7774 121.404 20.9574 121.089Z" fill="#FCB7A0"/>
    <path d="M48.2412 140.324C48.9142 140.479 49.5731 140.688 50.2119 140.95C50.5476 141.088 50.9313 140.927 51.0686 140.59C51.2062 140.254 51.0452 139.869 50.7096 139.732C50.0047 139.442 49.2775 139.212 48.5349 139.041C48.1811 138.96 47.829 139.181 47.7479 139.535C47.6668 139.89 47.8878 140.243 48.2412 140.324Z" fill="#FCB7A0"/>
    <path d="M60.6 153.064C60.3011 158.659 59.7887 167.953 59.7887 175.444H3.3798C2.86412 171.656 3.3798 167.249 3.62942 164.218C4.0262 159.542 4.69099 154.893 5.61986 150.293C7.26214 151.307 8.9504 151.939 10.7536 152.715C16.5706 155.223 22.7291 156.471 29.0387 157.264C37.7001 158.35 47.2647 158.834 55.6765 156.191C57.1479 155.724 60.0153 154.341 60.6 153.064Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M61.2559 153.1C61.2947 152.374 60.305 152.13 60.0029 152.79C59.6081 153.652 57.3112 154.981 55.4777 155.563C48.2507 157.834 39.953 157.969 29.1202 156.611C21.9116 155.704 16.2168 154.354 11.0131 152.111C10.7901 152.015 10.5625 151.918 10.2705 151.795C10.2557 151.789 10.2054 151.768 10.1371 151.739C9.95777 151.664 9.65493 151.536 9.54555 151.49C7.89112 150.791 6.94977 150.341 5.96441 149.733C5.57519 149.492 5.06641 149.714 4.97576 150.163C4.04196 154.787 3.37388 159.461 2.97481 164.162C2.94065 164.578 2.79711 166.204 2.79908 166.182C2.59741 168.51 2.5015 170.131 2.50183 171.799C2.50216 173.154 2.57376 174.393 2.72879 175.533C2.77313 175.859 3.05133 176.102 3.37979 176.102H59.7887C60.1513 176.102 60.4456 175.807 60.4456 175.444C60.4456 173.143 60.4939 170.562 60.5849 167.712C60.6723 164.972 60.7951 162.083 60.9554 158.854C61.0398 157.152 61.0641 156.691 61.2559 153.1ZM28.9569 157.917C39.9608 159.297 48.4248 159.159 55.8729 156.819C57.2428 156.384 58.762 155.637 59.8485 154.815C59.7401 156.852 59.7112 157.418 59.6432 158.788C59.4826 162.025 59.3594 164.921 59.2717 167.67C59.1889 170.265 59.1413 172.639 59.1331 174.786H3.96149C3.86262 173.866 3.81598 172.872 3.81565 171.799C3.81532 170.177 3.90926 168.589 4.10798 166.296C4.10568 166.322 4.24954 164.691 4.28403 164.272C4.65288 159.928 5.25396 155.606 6.08495 151.326C6.8854 151.764 7.7604 152.165 9.03546 152.703C9.14615 152.75 9.45162 152.878 9.6303 152.953C9.69763 152.982 9.7469 153.002 9.76135 153.009C10.0504 153.13 10.2747 153.226 10.4941 153.32C15.8269 155.619 21.6357 156.997 28.9569 157.917Z" fill="#91D5DD"/>
    <path d="M64.19 167.733C64.1887 167.525 64.0892 167.329 63.9217 167.206C63.7538 167.083 63.538 167.046 63.3393 167.108C61.3686 167.716 58.8362 168.467 56.9147 168.868C47.718 170.797 40.1438 171.386 29.873 170.965C24.1612 170.731 16.2651 169.945 10.9704 169.27C7.63658 168.845 4.48013 168.095 2.53239 167.269C2.3363 167.186 2.1123 167.203 1.93132 167.315C1.75067 167.426 1.63472 167.617 1.61928 167.828C1.25798 172.765 0.962372 177.271 0.962372 182.349C0.962372 187.493 0.962372 195 0.962372 195H64.0258C63.7203 189.55 63.9174 181.016 64.0751 175.421C64.1309 172.341 64.213 169.675 64.19 167.733Z" fill="#C31532"/>
    <path d="M61.2973 159.993C61.7046 160.475 61.4977 162.142 60.9406 163.465C60.7994 163.8 60.956 164.186 61.2904 164.327C61.6244 164.469 62.0097 164.312 62.151 163.977C62.8916 162.219 63.1488 160.147 62.3001 159.142C62.0656 158.865 61.6511 158.83 61.3742 159.065C61.0973 159.3 61.0628 159.715 61.2973 159.993Z" fill="#91D5DD"/>
    <path d="M59.4888 152.273C59.6399 152.321 59.9868 152.766 59.9605 152.986C59.9175 153.347 60.1747 153.675 60.535 153.718C60.8953 153.761 61.2221 153.503 61.2651 153.142C61.3729 152.239 60.5961 151.243 59.8846 151.018C59.5384 150.909 59.1696 151.101 59.0602 151.448C58.9512 151.794 59.143 152.164 59.4888 152.273Z" fill="#91D5DD"/>
    <path d="M37.8266 47.2071C36.0729 49.4481 34.9904 53.6578 35.2521 56.7682C35.508 59.7319 36.2894 62.6245 37.5599 65.3114C39.1732 68.6548 42.1425 71.1689 45.7262 72.5209C49.333 73.8815 53.9373 74.154 57.6006 73.0034C61.39 71.8133 64.5582 69.4631 66.0951 66.8115C67.826 63.7895 68.6133 61.0628 68.5486 57.9106C68.4475 53.0315 67.1445 48.5848 64.5796 45.6428C61.0493 41.5819 55.3161 39.9482 49.718 40.7446C44.8187 41.1386 40.9403 43.2219 37.8266 47.2071Z" fill="#4E6AF0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.4524 48.0615C47.2362 48.7494 45.3577 52.1985 46.3697 55.5758C46.9166 57.3981 48.1486 58.7949 49.6204 59.4416C49.4562 59.5473 49.2962 59.6638 49.1412 59.7908C46.5139 61.963 44.0738 65.7066 42.3323 70.0674L42.3175 70.1006C42.1888 70.3955 42.2916 70.7404 42.5609 70.9162C43.5525 71.5632 44.6115 72.1013 45.7187 72.5206C49.332 73.8795 53.9337 74.153 57.594 73.0034C60.6128 72.0575 63.2401 70.3777 64.977 68.355C65.1764 68.123 65.1895 67.7837 65.0089 67.5365L64.9826 67.5006C64.0567 66.093 63.0122 64.768 61.8606 63.5391C59.7809 61.3478 57.3552 59.7144 55.0439 59.0019C56.8622 58.0096 57.9162 56.1462 57.8837 53.7387C57.8311 49.8657 54.6642 47.166 50.4524 48.0615Z" fill="#EAF3F3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.2797 78.0145L53.2768 78.0148C49.8589 78.2488 42.4174 78.9696 40.737 79.299C40.4063 79.3639 40.178 79.6693 40.2092 80.006C40.2535 80.4842 40.3333 81.2402 40.4394 82.1995L40.4608 82.3934C40.5238 82.9614 40.5915 83.5591 40.6592 84.1492C40.6828 84.3559 40.7048 84.5468 40.7245 84.7176L40.7495 84.9318C40.7909 85.2886 41.1108 85.5463 41.4675 85.5104L41.5959 85.4976C41.701 85.487 41.8239 85.4749 41.9635 85.461C42.3622 85.4219 42.8128 85.3784 43.3068 85.3314C44.7182 85.1971 46.1913 85.0625 47.653 84.9371C48.3959 84.8736 49.1179 84.8137 49.8135 84.7584C50.9805 84.6656 52.0566 84.5869 53.0219 84.525C54.4182 84.4352 56.198 84.2815 58.2736 84.0765C58.9003 84.0146 59.548 83.9484 60.2131 83.879C61.7453 83.7184 63.2996 83.5469 64.7954 83.3751C65.3189 83.3149 65.7975 83.2589 66.2218 83.2089C66.3703 83.1915 66.5014 83.176 66.614 83.1625L66.7516 83.146C67.0693 83.1075 67.313 82.8456 67.3294 82.5257C67.3606 81.9079 67.3324 81.4883 67.1277 79.0117C67.089 78.5394 67.0607 78.182 67.0351 77.8328C67.0223 77.6577 67.0108 77.4906 67.0003 77.3286C66.9757 76.9485 66.6347 76.6684 66.258 76.7188C60.9134 77.4333 57.5158 77.7433 53.2797 78.0145ZM41.745 82.0547C41.6777 81.4442 41.6209 80.917 41.5775 80.4937C43.9339 80.1472 50.3154 79.537 53.3648 79.3283C57.4419 79.067 60.7534 78.7698 65.7387 78.1155C65.761 78.4127 65.786 78.7274 65.8185 79.1203C65.9509 80.7225 66.0074 81.4442 66.0215 81.9069C65.6103 81.9556 65.1488 82.0093 64.6456 82.0672C63.1541 82.2384 61.6041 82.4098 60.0768 82.5694C59.414 82.6389 58.7689 82.7047 58.1448 82.7663C56.0828 82.97 54.3163 83.1227 52.9378 83.2112C51.9652 83.2738 50.883 83.3527 49.7097 83.4459C49.0111 83.5015 48.2862 83.5617 47.541 83.6256C46.0747 83.7513 44.5977 83.8859 43.1827 84.0208C42.7462 84.0623 42.3435 84.1011 41.9802 84.1367L41.9644 83.9991C41.8971 83.4103 41.8295 82.814 41.7667 82.2476L41.745 82.0547Z" fill="#EAF3F3"/>
    <path d="M46.8827 89.4084L49.1786 89.2274C49.5403 89.1988 49.8106 88.8818 49.782 88.5195C49.7534 88.1571 49.4371 87.8863 49.0755 87.9149L46.7796 88.0959C46.418 88.1245 46.148 88.4415 46.1762 88.8038C46.2048 89.1662 46.5211 89.4367 46.8827 89.4084Z" fill="#EAF3F3"/>
    <path d="M53.1999 88.9082L55.4958 88.7239C55.8574 88.6946 56.1271 88.3773 56.0982 88.015C56.0693 87.6526 55.7527 87.3824 55.391 87.4114L53.0951 87.5957C52.7335 87.625 52.4638 87.9422 52.4927 88.3046C52.5216 88.6669 52.8383 88.9371 53.1999 88.9082Z" fill="#EAF3F3"/>
    <path d="M59.6367 88.3947L61.9326 88.2137C62.2942 88.1851 62.5645 87.8682 62.5359 87.5058C62.5074 87.1434 62.1914 86.8726 61.8294 86.9012L59.5335 87.0822C59.1719 87.1109 58.9019 87.4278 58.9301 87.7902C58.9587 88.1525 59.275 88.423 59.6367 88.3947Z" fill="#EAF3F3"/>
    <path d="M38.4099 15.6095C38.4418 16.4135 38.4477 17.0517 38.4463 18.4842C38.446 18.6095 38.446 18.6095 38.446 18.7349C38.4454 19.8754 38.4523 20.5433 38.4789 21.2748C38.492 21.638 38.7965 21.9219 39.1591 21.9087C39.5217 21.8955 39.8048 21.5902 39.7917 21.227C39.7661 20.516 39.7592 19.861 39.7598 18.7357C39.7598 18.6106 39.7598 18.6106 39.7602 18.4854C39.7615 17.0353 39.7552 16.385 39.7227 15.5575C39.7083 15.1943 39.4028 14.9114 39.0405 14.9258C38.6779 14.9402 38.3954 15.2463 38.4099 15.6095Z" fill="#FCD400"/>
    <path d="M36.988 18.9593C37.3647 18.9796 37.7352 18.9931 38.2903 19.0085C38.3616 19.0105 38.4194 19.012 38.5485 19.0155C39.6905 19.0465 40.1934 19.0678 40.7978 19.1221C41.1591 19.1546 41.4783 18.8874 41.5108 18.5254C41.543 18.1633 41.2766 17.8434 40.915 17.8109C40.2742 17.7533 39.7536 17.7312 38.584 17.6995C38.4552 17.6961 38.3977 17.6945 38.3265 17.6925C37.7822 17.6774 37.4216 17.6643 37.059 17.6447C36.6967 17.6251 36.3869 17.9035 36.3675 18.2665C36.3478 18.6295 36.6257 18.9397 36.988 18.9593Z" fill="#FCD400"/>
    <path d="M82.1467 50.4295C82.6298 50.41 83.0174 50.4127 83.8885 50.4314C84.0251 50.4347 84.0862 50.436 84.1627 50.4374C84.8538 50.4515 85.3071 50.4512 85.783 50.4295C86.1456 50.4127 86.4258 50.105 86.409 49.7416C86.3926 49.3786 86.0852 49.0979 85.7229 49.1143C85.2824 49.1347 84.8538 49.1347 84.1893 49.1212C84.1138 49.1196 84.0533 49.1186 83.917 49.1153C83.019 49.0959 82.6131 49.0933 82.0938 49.1143C81.7315 49.1288 81.4494 49.4349 81.4638 49.7982C81.4783 50.1616 81.7841 50.4443 82.1467 50.4295Z" fill="#EAF3F3"/>
    <path d="M84.5477 51.7706C84.5549 51.4346 84.5585 51.0969 84.5611 50.6174C84.5615 50.5354 84.5618 50.4696 84.5624 50.3222C84.5677 49.2091 84.5749 48.7207 84.6029 48.1309C84.6199 47.7679 84.3401 47.4595 83.9778 47.4424C83.6152 47.4253 83.3074 47.7057 83.2903 48.0691C83.2614 48.6838 83.2539 49.1841 83.2486 50.3159C83.248 50.4634 83.2476 50.5289 83.2473 50.6105C83.2447 51.0834 83.2411 51.4148 83.2342 51.7423C83.2263 52.1056 83.514 52.4068 83.8766 52.4144C84.2393 52.4223 84.5398 52.1339 84.5477 51.7706Z" fill="#EAF3F3"/>
    <path d="M76.9249 7.73748C76.9452 8.14766 76.9692 8.5321 77.0119 9.16432C77.0182 9.25358 77.0182 9.25358 77.0241 9.34274C77.1288 10.8864 77.1637 11.5757 77.1637 12.4405C77.1637 12.804 77.4579 13.0987 77.8206 13.0987C78.1835 13.0987 78.4775 12.804 78.4775 12.4405C78.4775 11.5361 78.4417 10.8266 78.3349 9.25345C78.329 9.16432 78.329 9.16432 78.3228 9.0753C78.2807 8.45028 78.2571 8.07206 78.237 7.67163C78.2187 7.30855 77.9102 7.02897 77.548 7.04717C77.1857 7.06533 76.9065 7.37444 76.9249 7.73748Z" fill="#90BFFF"/>
    <path d="M75.8929 10.7614C76.5783 10.8371 77.1177 10.8268 78.3323 10.7526C78.6171 10.7352 78.7465 10.7277 78.9048 10.7196C79.2112 10.7041 79.4671 10.6962 79.7092 10.6962C80.0721 10.6962 80.3661 10.4015 80.3661 10.038C80.3661 9.67445 80.0721 9.37976 79.7092 9.37976C79.4421 9.37976 79.1649 9.38829 78.8385 9.40487C78.6742 9.4132 78.5419 9.42097 78.2525 9.43861C77.1131 9.50815 76.6227 9.51753 76.0371 9.45282C75.6764 9.413 75.3519 9.67366 75.3122 10.035C75.2724 10.3963 75.5322 10.7216 75.8929 10.7614Z" fill="#90BFFF"/>
    <path d="M17.1296 68.1101C17.15 68.5202 17.1739 68.9046 17.217 69.5368C17.2229 69.6264 17.2229 69.6264 17.2288 69.7152C17.3336 71.2591 17.3684 71.9483 17.3684 72.8132C17.3684 73.1765 17.6627 73.4714 18.0253 73.4714C18.3882 73.4714 18.6822 73.1765 18.6822 72.8132C18.6822 71.9088 18.6464 71.1992 18.5397 69.626C18.5338 69.5368 18.5337 69.5368 18.5275 69.448C18.4855 68.823 18.4618 68.4448 18.4418 68.0443C18.4237 67.6813 18.1153 67.4016 17.7527 67.4197C17.3904 67.4381 17.1115 67.7471 17.1296 68.1101Z" fill="#90BFFF"/>
    <path d="M16.0979 71.134C16.7831 71.2097 17.324 71.1995 18.5387 71.1251C18.7059 71.1149 18.7824 71.1103 18.876 71.1051C19.303 71.0804 19.6134 71.0689 19.9142 71.0689C20.2769 71.0689 20.5712 70.7743 20.5712 70.4106C20.5712 70.047 20.2769 69.7524 19.9142 69.7524C19.5835 69.7524 19.2504 69.7649 18.8008 69.7909C18.7052 69.7962 18.6277 69.8008 18.4589 69.8113C17.3195 69.8808 16.8274 69.8903 16.2418 69.8255C15.8811 69.7857 15.5566 70.0463 15.5169 70.4077C15.4771 70.769 15.7373 71.0942 16.0979 71.134Z" fill="#90BFFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M84.5063 34.8232C84.5585 35.6351 85.7459 35.6483 85.8162 34.838C85.9797 32.9565 86.7027 31.9461 87.9748 31.8306C88.0763 31.8289 88.1801 31.8243 88.2868 31.8174C89.1319 31.7605 89.0899 30.5023 88.2428 30.5023C88.1308 30.5023 88.0204 30.5066 87.9124 30.5148C86.5591 30.5257 86.1791 30.0366 85.875 28.4483C85.8608 28.3748 85.8585 28.363 85.8224 28.169C85.7889 27.9899 85.7692 27.8863 85.7465 27.7741C85.5925 27.0169 84.4876 27.0991 84.4468 27.8708C84.4406 27.9847 84.438 28.0642 84.4321 28.2613C84.4242 28.5272 84.4186 28.6457 84.4045 28.781C84.37 29.1088 84.2902 29.3722 84.1151 29.6787C83.8063 30.2185 83.0539 30.5306 82.1309 30.5155C81.2868 30.502 81.2208 31.7536 82.0616 31.8293C83.1531 31.9277 83.7117 32.1528 83.8375 32.394C84.2232 33.1461 84.4514 33.9744 84.5063 34.8232ZM87.9337 30.5145C88.0188 30.5135 88.1071 30.5099 88.1988 30.5036L88.2027 30.5605C88.1242 30.5299 88.0346 30.5131 87.9337 30.5145ZM87.9958 31.8286C88.0756 31.822 88.1577 31.8187 88.2428 31.8187V31.7651C88.1715 31.7987 88.0891 31.821 87.9958 31.8286ZM84.4751 31.1845C84.747 30.992 84.9809 30.7564 85.1658 30.4783C85.3642 30.8334 85.6086 31.1174 85.915 31.3317C85.6408 31.5782 85.4053 31.8763 85.2089 32.2212C85.1451 32.0748 85.0768 31.9303 85.0039 31.7878C84.8801 31.5508 84.704 31.3511 84.4751 31.1845Z" fill="#FCD400"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0003 50.4025C11.8461 50.3465 11.805 49.0873 10.9573 49.0873C10.8469 49.0873 10.7385 49.0913 10.6321 49.0995C9.27031 49.1107 8.89259 48.622 8.5868 47.0205C8.57333 46.9504 8.57103 46.9386 8.53687 46.7549C8.50337 46.5749 8.48366 46.4715 8.461 46.3593C8.30728 45.602 7.20203 45.684 7.1613 46.4557C7.15539 46.5693 7.15243 46.6489 7.14652 46.8451C7.13897 47.1061 7.13339 47.2226 7.11992 47.3555C7.08609 47.6889 7.00595 47.9549 6.82989 48.2639C6.52049 48.8036 5.768 49.1153 4.84766 49.0972C4.00321 49.0811 3.93357 50.333 4.77442 50.4107C5.86752 50.5121 6.42622 50.7382 6.55202 50.9791C6.93631 51.7311 7.16491 52.5601 7.22075 53.4093C7.27462 54.2205 8.46035 54.2327 8.53096 53.4231C8.69453 51.5396 9.41878 50.5289 10.6935 50.4153C10.7934 50.4137 10.8955 50.4094 11.0003 50.4025ZM10.6531 49.0992C10.7369 49.0982 10.8239 49.0946 10.9142 49.0887L10.9178 49.1436C10.8403 49.114 10.7523 49.0979 10.6531 49.0992ZM10.7142 50.4133C10.7927 50.4071 10.8738 50.4038 10.9573 50.4038V50.3521C10.8866 50.3844 10.8058 50.4061 10.7142 50.4133ZM7.18955 49.7696C7.46151 49.5771 7.69537 49.3417 7.88029 49.0633C8.07835 49.4188 8.32272 49.7028 8.6295 49.9167C8.35524 50.1635 8.11973 50.4617 7.92299 50.807C7.8596 50.6605 7.79161 50.516 7.71869 50.3738C7.59486 50.1362 7.41881 49.9365 7.18955 49.7696Z" fill="#EAF3F3"/>
    <path d="M49.6325 9.83394V9.63976C49.6325 9.27626 49.3386 8.98154 48.9756 8.98154C48.613 8.98154 48.3187 9.27626 48.3187 9.63976V9.83394C48.3187 10.1975 48.613 10.4922 48.9756 10.4922C49.3386 10.4922 49.6325 10.1975 49.6325 9.83394Z" fill="#90BFFF"/>
    <path d="M97.4593 43.5615V43.3673C97.4593 43.0037 97.165 42.7091 96.8023 42.7091C96.4394 42.7091 96.1454 43.0037 96.1454 43.3673V43.5615C96.1454 43.9252 96.4394 44.2197 96.8023 44.2197C97.165 44.2197 97.4593 43.9252 97.4593 43.5615Z" fill="#90BFFF"/>
    <path d="M95.2947 19.7534V19.5625C95.2947 19.199 95.0004 18.9043 94.6378 18.9043C94.2749 18.9043 93.9809 19.199 93.9809 19.5625V19.7534C93.9809 20.1169 94.2749 20.4116 94.6378 20.4116C95.0004 20.4116 95.2947 20.1169 95.2947 19.7534Z" fill="#EAF3F3"/>
    <path d="M100.103 26.1119V25.9177C100.103 25.5542 99.809 25.2595 99.4464 25.2595C99.0835 25.2595 98.7895 25.5542 98.7895 25.9177V26.1119C98.7895 26.4754 99.0835 26.7701 99.4464 26.7701C99.809 26.7701 100.103 26.4754 100.103 26.1119Z" fill="#4E6AF0"/>
    <path d="M91.0642 5.20989V5.01571C91.0642 4.65217 90.7703 4.35748 90.4073 4.35748C90.0444 4.35748 89.7504 4.65217 89.7504 5.01571V5.20989C89.7504 5.57343 90.0444 5.86812 90.4073 5.86812C90.7703 5.86812 91.0642 5.57343 91.0642 5.20989Z" fill="#FCD400"/>
    <path d="M3.2747 57.6904V57.4995C3.2747 57.1358 2.9804 56.8413 2.61779 56.8413C2.25484 56.8413 1.96088 57.1358 1.96088 57.4995V57.6904C1.96088 58.0541 2.25484 58.3486 2.61779 58.3486C2.9804 58.3486 3.2747 58.0541 3.2747 57.6904Z" fill="#FCD400"/>
    <path d="M25.9838 21.4879V21.3924C25.9838 21.0289 25.6898 20.7342 25.3269 20.7342C24.9642 20.7342 24.67 21.0289 24.67 21.3924V21.4879C24.67 21.8514 24.9642 22.1461 25.3269 22.1461C25.6898 22.1461 25.9838 21.8514 25.9838 21.4879Z" fill="#EAF3F3"/>
    <path d="M29.9252 5.49945V5.40072C29.9252 5.03718 29.6313 4.74249 29.2683 4.74249C28.9057 4.74249 28.6114 5.03718 28.6114 5.40072V5.49945C28.6114 5.86299 28.9057 6.15768 29.2683 6.15768C29.6313 6.15768 29.9252 5.86299 29.9252 5.49945Z" fill="#4E6AF0"/>
    <path d="M69.8194 11.6637V11.3741C69.8194 11.0106 69.5254 10.7159 69.1625 10.7159C68.7999 10.7159 68.5056 11.0106 68.5056 11.3741V11.6637C68.5056 12.0273 68.7999 12.322 69.1625 12.322C69.5254 12.322 69.8194 12.0273 69.8194 11.6637Z" fill="#EAF3F3"/>
    <path d="M62.6101 2.22149V2.0306C62.6101 1.6671 62.3162 1.37238 61.9532 1.37238C61.5906 1.37238 61.2963 1.6671 61.2963 2.0306V2.22149C61.2963 2.58503 61.5906 2.87972 61.9532 2.87972C62.3162 2.87972 62.6101 2.58503 62.6101 2.22149Z" fill="#FCD400"/>
    <path d="M92.1317 68.1101V67.9159C92.1317 67.5523 91.8377 67.2577 91.4748 67.2577C91.1119 67.2577 90.8179 67.5523 90.8179 67.9159V68.1101C90.8179 68.4735 91.1119 68.7684 91.4748 68.7684C91.8377 68.7684 92.1317 68.4735 92.1317 68.1101Z" fill="#FCD400"/>
    <path d="M12.3565 33.8V33.6552C12.3565 33.2915 12.0625 32.997 11.6996 32.997C11.337 32.997 11.0427 33.2915 11.0427 33.6552V33.8C11.0427 34.1637 11.337 34.4582 11.6996 34.4582C12.0625 34.4582 12.3565 34.1637 12.3565 33.8Z" fill="#90BFFF"/>
    <path d="M87.2213 58.6613V58.5164C87.2213 58.1528 86.927 57.8582 86.5644 57.8582C86.2014 57.8582 85.9075 58.1528 85.9075 58.5164V58.6613C85.9075 59.0246 86.2014 59.3195 86.5644 59.3195C86.927 59.3195 87.2213 59.0246 87.2213 58.6613Z" fill="#4E6AF0"/>
    <path d="M18.699 53.4613V53.3198C18.699 52.9561 18.4047 52.6615 18.042 52.6615C17.6791 52.6615 17.3851 52.9561 17.3851 53.3198V53.4613C17.3851 53.8249 17.6791 54.1195 18.042 54.1195C18.4047 54.1195 18.699 53.8249 18.699 53.4613Z" fill="#4E6AF0"/>
    <path d="M79.1446 41.962V41.8172C79.1446 41.4536 78.8506 41.159 78.4877 41.159C78.1247 41.159 77.8307 41.4536 77.8307 41.8172V41.962C77.8307 42.3254 78.1247 42.6203 78.4877 42.6203C78.8506 42.6203 79.1446 42.3254 79.1446 41.962Z" fill="#EAF3F3"/>
    <path d="M5.49177 67.9883V67.8435C5.49177 67.4799 5.19747 67.1853 4.83486 67.1853C4.47192 67.1853 4.17795 67.4799 4.17795 67.8435V67.9883C4.17795 68.3517 4.47192 68.6466 4.83486 68.6466C5.19747 68.6466 5.49177 68.3517 5.49177 67.9883Z" fill="#EAF3F3"/>
    </svg>
    
);

export default Hand;
