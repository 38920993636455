import React, { useEffect, useState } from 'react';
import { Table, Pagination, Spin, message, Button, Flex, Typography, Card, Image, Input, Modal } from 'antd';
import { useDownloadReportMutation, useGetCampaignsQuery, useSearchCampaignsQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts'; // Ensure correct import path
import { convertUTCDateToLocalDate } from '../utils';
import PublicLeaderBoard from './PublicLeaderBoard';
import Title from 'antd/es/typography/Title';
import myImage from '../assets/images/AddCampaign.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import '../styles/CatalogsTable.scss';
import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import Column from 'antd/es/table/Column';
import PublicTagLeaderBoard from './PublicTagLeaderBoard';



interface CampaignsTableProps {
  handleRowClick: (record: Campaign) => void;
  groupId? : number;
}
const { Text } = Typography;

const CampaignsTable: React.FC<CampaignsTableProps> = ({ handleRowClick ,groupId}) => {
  const [key, setKey] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [value, setValue] = useState<string>("");
  const { data:campaignData , isFetching:isCampaignsFetching,isLoading:isCampaignsLoading,status} = useSearchCampaignsQuery({
    key: key,
    value: value,
    limit: 1000,
  },{skip:!key});
  // Define table columns
  // @ts-ignore
  const getColumnCampaignNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Campaign Name"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("title")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="title"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });
   // @ts-ignore
   const getColumnGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Group Name"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("group_name")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="group_name"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });
   // @ts-ignore
   const getColumnassociatedGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Group Name"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("associated_rep_group")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="associated_rep_group"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });

  // Define table columns
  // @ts-ignore
  const getColumnCampaigIdProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Pre Sale Id"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("pre_sale_id")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="pre_sale_id"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  const showModal = (recordId: string) => {
    if(!recordId) return
    recordId&& setId(recordId)
    setIsModalOpen(true);
  };
  const columns = [
    {
      title: 'Status',
      dataIndex: 'is_closed',
      key: 'is_closed',
      sorter: (a: Campaign, b: Campaign) => Number(a.is_closed) - Number(b.is_closed),
      render: (text: boolean) => (!text ? <Button style={{
        backgroundColor: "#EFFEF5",
        border: "1px solid #10A957",
        color: "#10A957",
        padding: 18,
        fontSize: "12px"
       
      }}>OPEN</Button> : <Button style={{
        backgroundColor: "#FFF0F0",
        border: "1px solid #FF5E5E",
        color:"#FF5E5E",
        padding: 18,
        fontSize: "12px"
       
      }}>CLOSED</Button>),
    },
    {
      title: 'PreSale ID',
      dataIndex: 'pre_sale_id',
      key: 'pre_sale_id',
      sorter: (a: Campaign, b: Campaign) => a.pre_sale_id - b.pre_sale_id,
      render: (text: string) => <strong>{text}</strong>, 
      ...getColumnCampaigIdProps('pre_sale_id')
    },
    {
      title: 'Group Name',
      dataIndex: ['group', 'name'], // Accessing nested group name
      key: 'group.name',
      sorter: (a: Campaign, b: Campaign) => a.group.name.localeCompare(b.group.name),
      ...getColumnGroupNameProps("group_id")
    },
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: Campaign, b: Campaign) => a.title.localeCompare(b.title),
      ...getColumnCampaignNameProps('name')
    },
   
    {
      title: 'End Date',
      dataIndex: 'end_date', // Adjust according to the actual data field
      key: 'end_date',
      sorter: (a: Campaign, b: Campaign) =>
        new Date(a.end_date).getTime() - new Date(b.end_date).getTime(),
      render: (text: string) => {
        const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
      
        return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
      },
    },
  
    {
      title: 'Money Raised',
      dataIndex: 'money_raised', // Adjust according to the actual data field
      key: 'money_raised',
      sorter: (a: Campaign, b: Campaign) => a.money_raised - b.money_raised,
      render: (amount: number) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount / 100);
      },
    },
    {
      title: 'Leaderboard', // New Leaderboard column
      key: 'leaderboard',
      render: (_: any, record: Campaign) => (
        <Button 
        style={{
          color:"##40A9FF",
          fontSize: 16,
        }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click
          handleLeaderBoardOpen(record);
        }}>
          <EyeOutlined  size={18} style={{
          color:"#40A9FF",
        }}/>
        <Text  style={{
          color:"#40A9FF",
          fontSize: 12,
          padding: "4px 1px"
        }}>View Leaderboard</Text>  
        </Button>
      ),
    },
    {
      title: 'Rep Group Associated',
      dataIndex: '',
      key: 'name',
      render: (record: any) => record.groups?.length&&record.groups[0]?.is_rep_group ? record.groups.map((g: { name: string }) => g.name).join(', ') : "-",
      ...getColumnassociatedGroupNameProps("associated_rep_group")
    },
    {
      title: 'Download Report',
      dataIndex: 'downloadReport',
      key: 'downloadReport',
      render: (text: string, record: Campaign) => (
        <Button onClick={(e)=> {
          showModal(record.id);
          e.stopPropagation(); // Prevent row click
        e.preventDefault()
       }} icon={<DownloadOutlined  size={18} style={{
          color:"#40A9FF",
        }}/>} style={{  width:"170px"}}>  <Text  style={{
          color:"#40A9FF",
          fontSize: 12,
          padding: "4px 1px"
        }}>Download Reports</Text> </Button>
      ),
    },
  ];
  const [isLeaderBoardVisible, setIsLeaderBoardVisible] = useState(false); // Sta
  const [isTagLeaderBoardVisible, setIsTagLeaderBoardVisible] = useState(false); // Sta
  const [campaignId, setCampaignId] = useState(""); // Sta
  const [group_id, setGroupId] = useState(0); // Sta
  const { currentPage, pageSize, onPageChange } = usePagination();
  const [downloadReport,{data:csv}] = useDownloadReportMutation();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);
  const handleLeaderBoardOpen = (data:Campaign) => {
    setCampaignId(data?.id)
    setGroupId(data?.group?.id)
    setIsLeaderBoardVisible(true); // Open the leaderboard modal
  };
  const handleTagLeaderBoardOpen = (data:Campaign) => {
    setCampaignId(data?.id)
    setIsTagLeaderBoardVisible(true); // Open the leaderboard modal
  };

  const handleLeaderBoardClose = () => {
    setIsLeaderBoardVisible(false); // Close the leaderboard modal
  };
  const handleTagLeaderBoardClose = () => {
    setIsTagLeaderBoardVisible(false); // Close the leaderboard modal
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDownload = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReport({ campaignId, reportType }).unwrap();
  
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        if(reportType==='based_frm_xml'){
          link.setAttribute('download', `report_${campaignId}_${reportType}.xml`);
        }else{
          link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        }
       
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      message.error('Failed to download report');
    }
  };
  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
        { data?.length? <Table
          
            columns={columns}
            dataSource={groupId?data.filter(item => item.group.id === groupId): key?campaignData:data || []}
            pagination={false}
            rowKey="id"
            loading={isCampaignsLoading||isLoading||isCampaignsFetching}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
            title={()=><Flex justify="space-between" align="center" className='flexRow'>
            <Title level={2}>Campaign List </Title>
            <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
              if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
              navigate(`/add-campaign`)
              }
            }}>+ Add New Campaign</Button>
          </Flex>}
          footer={() =>  <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
            showSizeChanger={false}
          />
          }
          />:
          <Card
          
          style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
          cover={<Image alt="example" src={myImage} preview={false}/>}
        >
          <Meta title={<Title level={2} style={{
            textAlign: "center",
            marginBottom: "0px"
          }}>No Campaign is added yet</Title>} description={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          }}>
<Text style={{
  color: "#595959",
  fontSize: 16,
  fontWeight: 400,
}}>You haven’t added Campaign, let’s add now by clicking below.</Text>
           <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
              if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
              navigate(`/add-campaign`)
              }
            }}>+ Add New Campaign</Button>
          </div>
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          />
          
        </Card>
        }
         
           {isLeaderBoardVisible&&
      <PublicLeaderBoard
      isClickable={true}
      campaignId={campaignId || '0'}
      groupId={group_id??groupId}
      visible={isLeaderBoardVisible}
      onClose={handleLeaderBoardClose}
      isMobile={false} // Or pass a prop to determine if the device is mobile
    />}
     {/* {isTagLeaderBoardVisible&&
      <PublicTagLeaderBoard
      isClickable={true}
      campaignId={campaignId || '0'}
      visible={isTagLeaderBoardVisible}
      onClose={handleTagLeaderBoardClose}
      isMobile={false} // Or pass a prop to determine if the device is mobile
    />} */}
        <Modal title="Download Reports" open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel}>
          <Button onClick={() => handleDownload(id, "based_order")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Order Report'}</Button>
          <Button onClick={() => handleDownload(id, "based_user")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >{ 'Download User Report'}</Button>
          <Button onClick={() => handleDownload(id, "based_product")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >  {'Download Product Report'}</Button>
         {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "finance_based_order")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download Accounting Report </Button>}
            {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "based_frm_xml")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download FRM XML Report </Button>}
      </Modal>
        </>
      )}
    </>
  );
};

export default CampaignsTable;
