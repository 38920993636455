import React from 'react';
import { Card, Button, Tag } from 'antd';
import '../styles/CampaignCard.scss'; // Make sure this path is correct
import Meta from 'antd/es/card/Meta';
import ShareButtonStatic from './ShareButtonStatic';
import Title from 'antd/es/typography/Title';

type CampaignCardProps = {
  name: string;
  amountRaised: string;
  endDate: string;
  status: string;
  onDetailsClick: () => void;
  campaignId: string;
  userId: number,
  title: string,
  blurb: string,
  link: string
};

const ActiveCampaignCard: React.FC<CampaignCardProps> = ({
  name,
  amountRaised,
  endDate,
  status,
  onDetailsClick,
  campaignId,
  userId,
  title,
  blurb,
  link
}) => {
    const handleNavigateToCampaign = () => {
        window.open(`${process.env.REACT_APP_BASE_URL_FE}raise/${link}/${userId}`, '_blank');
      };
  return (
    <Card className={`campaign-card ${status.toLowerCase()}`} style={{
      margin: 0,
      border: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }}>
      <Tag bordered={false} color={status==="Active"?"green":"red"} style={{
        padding:"4px 12px",
       
       
      
      }}>
      {status==="Active"?"Active Campaign": "Closed Campaign"}
      </Tag>
      <Title level={2} style={{
        marginBlockStart:5,
        marginBlockEnd:5
      }}
      ellipsis={{
        rows:1
      }}
      >
      {`${name.slice(0,20)}...`}
      </Title>
      <div style={{
        marginTop:10
      }}>

      </div>
      <Meta 
        title={endDate} 
        description="End Date:" 
      />
      <div style={{
        marginTop:10
      }}>

      </div>
      {/* <Meta 
        title={amountRaised} 
        description="Raised by Me:" 
      /> */}
      <div style={{
        marginTop:10
      }}>

      </div>
      <ShareButtonStatic 
          campaignId={campaignId ?? ''}
          userId={userId?? 0}
          showEmail={false}
            schoolName={title ?? ''}
            blurb={blurb ?? ''}
           />
       {/* <Meta 
        title={ <ShareButtonStatic 
          campaignId={campaignId ?? ''}
          userId={userId?? 0}
          showEmail={false}
            schoolName={title ?? ''}
            blurb={blurb ?? ''}
           />} 
        description="Share" 
      /> */}
   
     <div className={`details-button ${status.toLowerCase()}`}>
     {/* <span className="details-text">{status} Campaign</span> */}
     <Button type="primary" onClick={handleNavigateToCampaign}>Open store</Button>
        <Button type="primary"  style={{
          backgroundColor: "#ffffff",
          border: "1px solid #C31532",
          color:"#C31532"
        }}  onClick={onDetailsClick}>Details</Button>
      </div>
    </Card>
  );
};

export default ActiveCampaignCard;
