// src/components/AccountssignupModal.tsx

import React from 'react';
import { Modal, Spin, Table } from 'antd';
import { useGetReportSignupsQuery } from '../services/admin/inventoryApi';
import { User } from '../types.ts';
import { useDispatch } from 'react-redux';
import { setUserId } from '../slices/admin/adminUserSlice';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import { useNavigate } from 'react-router-dom';

interface AccountsignupModalProps {
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
  data: User[];
  loading: boolean;
}

const UsersGroupModal: React.FC<AccountsignupModalProps> = ({ visible, onClose, data ,isMobile,loading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    { title: 'Email Address', dataIndex: 'email', key: 'email' ,
    render: (text: string, record: any) => (
      <>
    {<a onClick={() => handleNameClick(record.id, record.full_name)} style={{ cursor: 'pointer' }}>
    {text}
  </a>
} 
</>
    ),
  },
    { title: 'Name', dataIndex: 'full_name', key: 'full_name' },
    { title: 'First Sale', dataIndex: 'donations', key: 'donations' },
    { title: 'Invited Users Count', dataIndex: 'contacts', key: 'contacts' },
    // { title: 'Account blurb created', dataIndex:'account_blurb_created', key: 'account_blurb_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},
    // { title: 'Account goal created', dataIndex:'account_goal_created', key: 'account_goal_created',render: (text: boolean) => (text ? 'Yes' : 'No'),},
    { title: 'Is Active', dataIndex:'is_active', key: 'is_active',render: (text: boolean) => (text ? 'Yes' : 'No'),},
  ];
  const handleNameClick = (userId: string, name: string) => {
    dispatch(setUserId(userId));
    dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
    navigate(`/dashboard/users`, { state: { name } })
  };
  return (
    <Modal
      title="User Account Signups"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Spin spinning={loading}>

     
      <Table
      // @ts-ignore
        dataSource={data|| []}
        rowKey="email_address"
        columns={columns}

        scroll={{ x: isMobile?'100vw':undefined }} // Enable horizontal scroll

  
      />
       </Spin>
    </Modal>
  );
};

export default UsersGroupModal;
