import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message, Flex, Button, Card, Image, Typography } from 'antd';
import { useGetInventoryCatalogsQuery } from '../services/admin/inventoryApi';
import  usePagination from '../hooks/usePagination';
import { Catalog, Item } from '../types.ts'; // Ensure correct import path
import '../styles/CatalogsTable.scss';
import Title from 'antd/es/typography/Title';
import myImage from '../assets/images/AddCatalog.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import Meta from 'antd/es/card/Meta';
const { Text } = Typography;
const columns = [
  {
    title: 'Catalog Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Description',
    dataIndex: 'blurb',
    key: 'blurb',
    sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
  },
  // Add more columns as necessary
];

interface CatalogsTableProps {
  handleRowClick: (record: Catalog) => void;
}
const CatalogsTable: React.FC<CatalogsTableProps> = ({handleRowClick}) => {
  const { currentPage, pageSize, onPageChange } = usePagination();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const user = useSelector((state: RootState) => state.adminUser.user);
  
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetInventoryCatalogsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });


  useEffect(() => {
    if (error) {
      message.error('Failed to load catalogs');
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>{data?.length?
          <Table
           title={()=><Flex justify="space-between" align="center" className='flexRow'>
           <Title level={2}>Catalog List </Title>
           <Button type='primary' onClick={()=>{
             if(!user ) return null
             if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
             if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
             navigate(`/add-catalog`)
             }
           }}>+ Add New Catalog</Button>
         </Flex>}
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
             rowClassName="clickable-row"
             footer={() => <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={0}
              onChange={onPageChange}
              showSizeChanger={false}
            />
            }
          />
          :<Card
          
          style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
          cover={<Image alt="example" src={myImage} preview={false}/>}
        >
          <Meta title={<Title level={2} style={{
            textAlign: "center",
            marginBottom: "0px"
          }}>No Catalog is added yet</Title>} description={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          }}>
<Text style={{
  color: "#595959",
  fontSize: 16,
  fontWeight: 400,
}}>You haven’t added Catalog, let’s add 
now by click below.
</Text>
           <Button type='primary' onClick={()=>{
                if(!user ) return null
                if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
                if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
                navigate(`/add-catalog`)
                }
            }}>+ Add New Catalog</Button>
          </div>
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          />
          
        </Card>}
        </>
      )}
    </>
  );
};

export default CatalogsTable;