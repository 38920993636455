import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { updateAddress } from '../slices/campaign/addressSlice';
import { Modal, Button, List, Image, Spin, Card, Flex, Tag } from 'antd';
import CurrencyInput from 'react-currency-input-field';
//@ts-ignore
import {validate} from "react-email-validator";
import { usePlacesWidget } from 'react-google-autocomplete';
import '../styles/Checkout.scss';
import { addItem, removeItem, resetCart, setCartItems } from '../slices/campaign/cartSlice';
import { setDonationAmount } from '../slices/campaign/donationSlice';
import { Product } from '../types.ts';
import { formatPrice } from '../utils';
import { InputMask } from '@react-input/mask';
import { HostedForm, useAcceptJs } from 'react-acceptjs';
import {
  useGetCampaignForStudentQuery,
  useGetCampaignForUserQuery,
  useCreateOrderMutation,
  useCalculateShippingMutation,
  usePostMessageMutation,
} from '../services/campaign';
import { useParams } from 'react-router-dom';
import OrderSuccess from './OrderSuccess';
import { Alert, notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import CreditCardForm from './CreditCardForm';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { EllipsisOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import FreebieModal from './FreebieModal';
import CartItem from './CartItem';
import OrderSummary from './OrderSummary';
import MessageForm from './MessageForm';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
);

const authData = {
  apiLoginID: process.env.REACT_APP_API_LOGIN_ID ?? '',
  clientKey: process.env.REACT_APP_CLIENT_KEY ?? '',
};

type AuthNetEnvironment = 'PRODUCTION' | 'SANDBOX';

const environment = process.env.REACT_APP_ENV_AUTH as AuthNetEnvironment;


type NotificationPlacement = NotificationArgsProps['placement'];
type BasicCardInfo = {
  cardNumber: string;
  cardExpiry: string;
  cardCode: string;
  firstName: string;
  lastName: string;
  zipCode: string;
};
interface Message {
  code: string;
  text: string;
}

interface ErrorResponse {
  message: Message[];
  resultCode: string;
}
interface err {
  messages: ErrorResponse;
}
const Checkout: React.FC = () => {
  const dispatch = useDispatch();
  const { id, campaign_id, user_id } = useParams<{
    id: string;
    campaign_id: string;
    user_id: string;
  }>();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const address = useSelector((state: RootState) => state.address);

  const [Phonenumber, setforphonenumber] = useState('');
  const [studentName, setstudentName] = useState('');
  const [orderId, setOrderId] = useState('');

  const [isQuote, setIsQuote] = useState(true);
  const [shippingAmount, setShippingAmount] = useState(0);
  const [quoteNumber, setQuoteNumber] = useState(0);
  const [api, contextHolder] = notification.useNotification();
  const Context = React.createContext({ name: 'Default' });
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [errorOrderPlaced, setErrorOrderPlaced] = useState(true);
  const [errorShipping, setErrorShipping] = useState(true);
  const [showCalculateShipping, setShowCalculateShipping] = useState(true);
  const { dispatchData, loading, error } = useAcceptJs({ environment, authData });

  const [cardData, setCardData] = React.useState({
    cardNumber: '',
    month: '',
    year: '',
    cardCode: '',
    firstName: '',
    lastName: '',
    zipCode: '',
    cardExpiry: '',
  });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    street1: '',
    city: '',
    state: '',
    postalCode: '',
  });
  const openNotification = (
    placement: NotificationPlacement,
    data?: string
  ) => {
    api.error({
      message: data ?? `Something went wrong`,
      placement,
    });
  };
  const { data: campaignData } = useGetCampaignForUserQuery(
    campaign_id || '0',
    {
      skip: !campaign_id,
    }
  );
  const finalDonationAmount = useSelector((state: RootState) => state.donation.amount);
const donationAmount = campaignData?.donations_enabled ? finalDonationAmount : 0;
  
  const [donationInput, setDonationInput] = useState(
    (donationAmount / 100).toFixed(2)
  );
  const { data: userData } = useGetCampaignForStudentQuery(
    { userId: user_id || '0', campaignId: campaign_id ?? '0' },
    { skip: !user_id || !campaign_id }
  );

  const [createOrder, { isError, isLoading }] = useCreateOrderMutation();
  
  const [
    calculateShipping,
    { isError: isShippingError, isLoading: isCalculateShippingLoading },
  ] = useCalculateShippingMutation();
  const fee_platform_percentage = campaignData?.fee_platform_percentage || 0; // Example value, replace with actual percentage
  const fee_platform_base = campaignData?.fee_platform_base || 0; // Example value, replace with actual base fee
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const isDivisibleBy4 = () => totalItems % 4 === 0;

  const [freeBieModal, setFreebieModal] = useState(
    !isDivisibleBy4() && campaignData?.ship_promo_enabled
  );
  const totalAmount =
    cartItems.reduce((total, item) => total + item.cost * item.quantity, 0) +
    donationAmount +
    shippingAmount;
  const processingFee =
    ((totalAmount + donationAmount) * fee_platform_percentage) / 100 +
    fee_platform_base;
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_ADDRESS_KEY,
    onPlaceSelected: (place: any) => handlePlaceSelected(place),
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'usa' },
    },
  });

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  function compareAndResetCart(cartItems: Product[], inventoryData: Product[]): Product[] {
    const updatedCartItems: Product[] = [];
  
    for (const cartItem of cartItems) {
      const inventoryItem = inventoryData.find(item => item.id === cartItem.id);
  
      if (inventoryItem) {
        // Check if all parameters match
        const isMatch = cartItem.cost === inventoryItem.cost &&
                        cartItem.title === inventoryItem.title &&
                        cartItem.primary_image_url === inventoryItem.primary_image_url &&
                        cartItem.fulfilment_type === inventoryItem.fulfilment_type &&
                        cartItem.blurb === inventoryItem.blurb;
  
        if (isMatch) {
          updatedCartItems.push(cartItem); // If matched, keep the item
        } else {
          // Reset cart if any mismatch found
          console.warn(`Cart item with id ${cartItem.id} does not match inventory. Resetting cart...`);
          return []; // Return an empty array to reset the cart
        }
      } else {
        // Item not found in inventory, reset the cart
        console.warn(`Cart item with id ${cartItem.id} not found in inventory. Resetting cart...`);
        return [];
      }
    }
  
    return updatedCartItems; // Return the updated cart items if all match
  }
  const handlePlaceSelected = (place: any) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
    };

    for (const component of addressComponents) {
      const addressType = component.types[0];
      if (addressType === 'street_number') {
        newAddress.street1 += component.long_name + ' ';
      } else if (addressType === 'route') {
        newAddress.street1 += component.long_name;
      } else if (addressType === 'locality') {
        newAddress.city = component.long_name + ' ';
      } else if (addressType === 'sublocality_level_1') {
        newAddress.city += component.long_name;
      } else if (addressType === 'administrative_area_level_1') {
        newAddress.state = component.short_name;
      } else if (addressType === 'postal_code') {
        newAddress.postalCode = component.long_name;
      }
    }
    dispatch(updateAddress(newAddress));
    setShowCalculateShipping(true);
    setErrorOrderPlaced(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateAddress({ ...address, [name]: value }));
    setShowCalculateShipping(true);
    setErrorShipping(true);
    setErrorOrderPlaced(true);
    setErrorShipping(true);
    setValidationErrors({
      ...validationErrors,
      [name]: '',
    });
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setstudentName(value);
  };
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);
  const handleAddToCart = (product: Product) => {
    setShowCalculateShipping(true);
    setErrorShipping(true);
    setErrorOrderPlaced(true);
    dispatch(addItem(product));
  };

  const handleRemoveFromCart = (id: string) => {
    setShowCalculateShipping(true);
    setErrorShipping(true);
    setErrorOrderPlaced(true);
    dispatch(removeItem(id));
  };

  const handleResetCart = () => {
    dispatch(resetCart());
  };
  useEffect(() => {
    const finalCartItems = compareAndResetCart(cartItems, campaignData?.products?? []);
    // Any setup code can go here
    //@ts-ignore
    dispatch(setCartItems(finalCartItems));
    return () => {
      // This function is called when the component unmounts
      console.log('Component is unmounting');
      // Call your function here
      if(
        orderPlaced
      ){
        handleResetCart();
      }
    
    };
  }, []);
  const handleDonationChange = (value: string) => {
    const amount = Number(value) * 100;
    setDonationInput(value);
    setErrorShipping(true);
    setErrorOrderPlaced(true);
    dispatch(setDonationAmount(amount));
  };
  function extractString(input: string) {
    const regex = /\['(.*)'\]/;
    const match = input.match(regex);
    return match ? match[1] : null;
  }
  const handleCalculateShipping = async () => {
    if (validateForm()) {
      try {
        const response = await calculateShipping({
          campaign_id: parseInt(campaignData?.id ?? '1'),
          user_id: Number.isInteger(Number(user_id))
            ? Number(user_id)
            : undefined,
          email: address?.email || '',
          recipient_first_name: address.firstName,
          recipient_last_name: address.lastName,
          recipient_address_1: address.street1,
          recipient_address_2: address.street2,
          recipient_address_city: address.city,
          recipient_address_state: address.state,

          recipient_address_zip: address.postalCode,
          product_ids: cartItems.flatMap((item) =>
            Array(item.quantity).fill(item.id)
          ),
          is_quote: true,
          amount_donation: donationAmount,
          amount_product: Math.ceil(
            cartItems?.reduce(
              (total, item) => total + item?.cost * item.quantity,
              0
            )
          ),
          amount_platform_fee: Math.ceil(processingFee),
          amount_total: Math.ceil(totalAmount + processingFee),
          student_name_freeform: studentName,
        }).unwrap();
        setErrorShipping(response.is_success);
        if (!response.is_success) {
          openNotification('topLeft', extractString(response.message) ?? '');
        }
        setShippingAmount(response.shipping_quote);
        setQuoteNumber(response.quote_number);
        setShowCalculateShipping(false);
      } catch (error) {
    // @ts-ignore
        openNotification('topLeft', error?.data?.detail as string);
        setErrorShipping(false);
        console.error('Failed to calculate shipping:', error);
      }
    }
  };

  const handlePlaceOrder = async (response: any) => {
    console.log(cardData);

    if (validateForm()) {
      try {
        console.log(cardData);
        const [firstPart, secondPart] = cardData.cardExpiry.split(' / ');

        // Extract the first two digits from the first part and the last two digits from the second part
        const firstTwoDigits = firstPart.substring(0, 2);
        const lastTwoDigits = secondPart.substring(secondPart.length - 2);
        const response = await dispatchData({
          cardData: {
            ...cardData,
            cardNumber: cardData.cardNumber.replace(/\s+/g, ''),
            month: firstTwoDigits,
            year: lastTwoDigits,
            fullName: `${cardData.firstName} ${cardData.lastName}`,
            zip: cardData.zipCode,
          },
        });
        console.log(cardData, response);
        const data = await createOrder({
          campaign_id: parseInt(campaignData?.id ?? '1') || '',
          user_id: user_id || '',
          email: address?.email || '',
          recipient_first_name: address.firstName,
          recipient_last_name: address.lastName,
          recipient_address_1: address.street1,
          recipient_address_2: address.street2,
          recipient_address_city: address.city,
          recipient_address_state: address.state,
          recipient_address_zip: address.postalCode,
          amount_product: Math.ceil(
            cartItems?.reduce(
              (total, item) => total + item?.cost * item.quantity,
              0
            )
          ),
          amount_platform_fee: Math.ceil(processingFee),
          recipient_phone: Phonenumber.length ? Phonenumber : null,
          amount_donation: donationAmount,
          amount_shipping: shippingAmount,
          amount_total: Math.ceil(totalAmount + processingFee),
          product_ids: cartItems.flatMap((item) =>
            Array(item.quantity).fill(item.id)
          ),
          card_token: response.opaqueData.dataValue,
          is_quote: false,
          quote_number: quoteNumber,
          student_name_freeform: studentName,
        }).unwrap();
      
        setErrorOrderPlaced(data.is_success);
        if (!data.is_success) {
          openNotification('topLeft', extractString(data.message) ?? '');
        }
        setOrderId(data.id);
        setOrderPlaced(data.is_success);
        console.log('Order placed successfully');
      } catch (error) {
        // const errorv = error as err;
        // const st = errorv?.messages.message
        //   .map((message) => message.text)
        //   .join(', ');
        //@ts-ignore
        openNotification('topLeft', error?.data?.detail as string);

        console.error('Failed to place order:', error);
      }
    }
  };
  const getCartItemQuantity = (product: Product) => {
    const item = cartItems.find((item) => item.id === product.id);
    return item ? item.quantity : 0;
  };

  const closeModal = () => setIsModalOpen(false);
  const validateForm = () => {
    const errors: any = {};

    if (!address.email) {
      errors.email = 'Email is required';
    } else if (!validate(address.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!address.firstName) {
      errors.firstName = 'First name is required';
    }
    if (!address.street1) {
      errors.street1 = 'Address line 1 is required';
    }
    if (!address.city) {
      errors.city = 'City is required';
    }
    if (!address.state) {
      errors.state = 'State is required';
    }
    if (!address.postalCode) {
      errors.postalCode = 'Postal Code is required';
    }
    if (!address.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (Phonenumber && !/^\d{10}$/.test(Phonenumber)) {
      errors.phone = 'Phone number is invalid';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  if (campaignData?.is_closed) {
    return <OrderSuccess closed={true} />;
  }
  return (
    <>
      {contextHolder}
      <div className="user-name">
        <img
          src={campaignData?.logo_image_url}
          alt="School Logo"
          className="school-logo"
        />
        {userData?.title ? (
          <h1 style={{ textAlign: 'center' }}>
            Checkout for - {campaignData?.title} - {userData?.title}
          </h1>
        ) : (
          <h1 style={{ textAlign: 'center' }}>
            Checkout for - {campaignData?.title}{' '}
          </h1>
        )}
      </div>
      {!orderPlaced ? (

        <div className="checkout-container">
          <div className="checkout-content">
            <div className="checkout-form">
              <h2>Contact</h2>
              <form>
                <div className="form-row">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      value={address.email}
                      onChange={handleChange}
                      name="email"
                      required
                      placeholder="Email"
                    />
                    {validationErrors.email && (
                      <p className="error-message">{validationErrors.email}</p>
                    )}
                  </div>
                  <div className="form-group phoneinput">
                    <InputMask
                      mask="(___) ___-____"
                      replacement={{ _: /\d/ }}
                      placeholder="(xxx) yyy-yyyy"
                      onMask={(e) => {
                        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                        setforphonenumber(value);
                        setValidationErrors({
                          ...validationErrors,
                          phone: '',
                        });
                      }}
                    />
                    <Tooltip title="Provide your phone number in case there is a delivery issue.">
                      <QuestionCircleOutlined
                        style={{ marginLeft: 8, color: '#1890ff' }}
                      />
                    </Tooltip>

                    {validationErrors.phone && (
                      <p className="error-message">{validationErrors.phone}</p>
                    )}
                  </div>
                </div>
                <h2>Shipping Address</h2>
                <Card
    style={{
      backgroundColor: '#f5f7f9',
      borderRadius: '8px',
      display: 'flex',
      marginBottom: '20px',
      alignItems: 'center',
      padding: '16px',
      border: 'none'
    }}
    bodyStyle={{ display: 'flex', alignItems: 'center', padding: '0' }}
  >
    <InfoCircleOutlined style={{ fontSize: '24px', color: '#5b9bd5', marginRight: '10px' }} />
    <span>We can't ship to P.O. Boxes. Please include your apartment number to avoid delays.</span>
  </Card>
                <div className="form-row">
                  <div className="form-group">
                    <input
                      type="text"
                      name="firstName"
                      value={address.firstName}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="First Name"
                    />
                    {validationErrors.firstName && (
                      <p className="error-message">
                        {validationErrors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="lastName"
                      value={address.lastName}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Last Name"
                    />
                    {validationErrors.lastName && (
                      <p className="error-message">
                        {validationErrors.lastName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <input
                    ref={ref}
                    type="text"
                    name="street1"
                    value={address.street1}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Address Line 1"
                  />
                  {validationErrors.street1 && (
                    <p className="error-message">{validationErrors.street1}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="street2"
                    value={address.street2}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Address Line 2"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <input
                      type="text"
                      name="city"
                      value={address.city}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="City"
                    />
                    {validationErrors.city && (
                      <p className="error-message">{validationErrors.city}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="state"
                      value={address.state}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="State"
                    />
                    {validationErrors.state && (
                      <p className="error-message">{validationErrors.state}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="postalCode"
                      value={address.postalCode}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Postal Code"
                    />
                    {validationErrors.postalCode && (
                      <p className="error-message">
                        {validationErrors.postalCode}
                      </p>
                    )}
                  </div>
                </div>

                {showCalculateShipping && (
                  <button
                    type="button"
                    className="submit-button"
                    onClick={handleCalculateShipping}
                    disabled={isCalculateShippingLoading}
                  >
                    Calculate Shipping
                    {isCalculateShippingLoading && <Spin indicator={antIcon} />}
                  </button>
                )}
                <div
                  style={{
                    display: !showCalculateShipping ? '' : 'none',
                    width: '100%',
                  }}
                >
                  {/* <HostedForm authData={authData} onSubmit={handlePlaceOrder}
                  buttonText='CHECKOUT'
                  environment='SANDBOX'
                /> */}
                  <button
                    type="button"
                    className="checkout-button"
                   
                    onClick={() => setIsModalOpen(true)}
                  >
                    Checkout
                  </button>

                  <FreebieModal
                    visible={!!freeBieModal}
                    onCancel={() => setFreebieModal(false)}
                    products={campaignData?.products || []}
                    getCartItemQuantity={getCartItemQuantity}
                    handleAddToCart={(product) => {
                      handleAddToCart(product);
                      setFreebieModal(false);
                    }}
                    handleRemoveFromCart={handleRemoveFromCart}
                  />
                  <Modal
                    title=""
                    visible={isModalOpen}
                    onCancel={closeModal}
                    footer={null}
                  >
                    <CreditCardForm
                      cardData={cardData}
                      // @ts-ignore
                      setCardData={setCardData}
                      handleSubmit={handlePlaceOrder}
                      disabled={loading || isLoading}
                      loading={loading || isLoading}
                      error={error}
                    />
                  </Modal>
                </div>
              </form>
            </div>
            <div className="checkout-summary">
              {campaignData?.donations_enabled&&
              <>
            <h2>Select Donation Amount</h2>
            <Flex gap={2} wrap align="center">
      {[10,20,25,50,75,100,0].map<React.ReactNode>((tag) => (
        <Tag.CheckableTag
        style={{
          borderColor:"#d9d9d9",
          padding:6,
          fontSize: "18px"
        }}
          key={tag}
          checked={donationAmount===tag*100}
          // @ts-ignore
          onChange={(checked) => handleDonationChange(tag)}
        >
          ${tag}
        </Tag.CheckableTag>
      ))}
    </Flex></>}
              <h2>Cart Summary</h2>
              <ul>
                {cartItems.map((item) => (
                  <CartItem
                    item={item}
                    handleRemoveFromCart={handleRemoveFromCart}
                    handleAddToCart={() => handleAddToCart(item)}
                  />
                ))}
              </ul>
            
              <OrderSummary
              subTotal={cartItems.reduce(
                (total, item) => total + item.cost * item.quantity,
                0
              )}
                donationInput={donationInput}
                shippingAmount={shippingAmount}
                processingFee={processingFee}
                totalAmount={totalAmount}
                handleDonationChange={handleDonationChange}
              />
              {(!user_id || user_id == 'undefined') && (
                <>
                  <h2 className="student-title">Seller Name</h2>
                  <h4 className="student-subtitle">
                    Enter a seller name to make sure they get credit
                  </h4>
                  <div className="form-student">
                    <input
                      type="text"
                      value={studentName}
                      onChange={handleNameChange}
                      placeholder="Name"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='checkout-container'>
        <div className='checkout-summary'>
          <OrderSuccess closed={false} 
          display={<>
           <ul>
                {cartItems.map((item) => (
                  <CartItem
                  showButtons={false}
                    item={item}
                    handleRemoveFromCart={handleRemoveFromCart}
                    handleAddToCart={() => handleAddToCart(item)}
                  />
                ))}
              </ul>
          <OrderSummary
          subTotal={cartItems.reduce(
            (total, item) => total + item.cost * item.quantity,
            0
          )}
          showInput={false}
                donationInput={donationInput}
                shippingAmount={shippingAmount}
                processingFee={processingFee}
                totalAmount={totalAmount}
                handleDonationChange={handleDonationChange}
              />
              <MessageForm 
              campaign_id={campaign_id ?? null}
              user_id={user_id ?? null}
              order_id={orderId}
           
              />
          </>}
          />
        </div>
        </div>
      )}

      <Modal
        title="Add another item for a dollar or less in shipping!"
        visible={freeBieModal}
        bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
        onCancel={() => {
          setFreebieModal(false);
        }}
        footer={null}
      >
        <List
          itemLayout="vertical"
          size="large"
          dataSource={campaignData?.products}
          renderItem={(item) => (
            <li key={item.id} className="item-list">
              <div className="item-info">
                <img src={item.primary_image_url} alt={item.title} />
                <div>
                  <h3>
                    {item.title?.length > 20
                      ? item.title.substring(0, 20) + '...'
                      : item.title}
                  </h3>
                  <p>${formatPrice(item.cost)}</p>
                </div>
              </div>
              <div className="item-action-container">
                <div className="item-actions">
                  <button
                    className="action-button"
                    onClick={() => handleRemoveFromCart(item.id)}
                  >
                    -
                  </button>
                  <span className="quantity">{getCartItemQuantity(item)}</span>
                  <button
                    className="action-button"
                    onClick={() => {
                      handleAddToCart(item);
                      setFreebieModal(false);
                    }}
                  >
                    +
                  </button>
                </div>
                <div className="item-price">
                  ${formatPrice(getCartItemQuantity(item) * item.cost)}
                </div>
              </div>
            </li>
          )}
        />
      </Modal>
    </>
  );
};

export default Checkout;
