import React, { useEffect, useState } from 'react';
import { Table, Pagination, Spin, message, Select, Button, Input, Flex, Card, Image, Typography } from 'antd';
import { useGetGroupsQuery, useGetUsersQuery, useSearchUsersQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Group, User } from '../types.ts'; // Ensure correct import path
import { Option } from 'antd/es/mentions';
import { useLocation, useNavigate } from 'react-router-dom';
import myImage from '../assets/images/AddUser.png';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupId, setUserId } from '../slices/admin/adminUserSlice';
import { RootState } from '../store';

import Title from 'antd/es/typography/Title';
import "../styles/ProductsTable.scss"; 
import Meta from 'antd/es/card/Meta';
import { SearchOutlined } from '@ant-design/icons';

const { Text } = Typography;
interface UsersTableProps {
  handleRowClick?: (record: User) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ handleRowClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupId = useSelector((state: RootState) => state.adminUser.groupId);
  const location = useLocation();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const user = useSelector((state: RootState) => state.adminUser.user);
  const userName = location.state?.name;

  const userId = useSelector((state: RootState) => state.adminUser.userId);
  const [searchTermUser, setSearchTermUser] = useState<string>(userName?userName:'');
  const [key, setKey] = useState<string>(userName?"full_name":'');
  const [debouncedSearchTermUser, setDebouncedSearchTermUser] = useState<string>(searchTermUser);
  const { currentPage, pageSize, onPageChange, onPageSizeChange } = usePagination();
  const { data:allUsers , isFetching:isUsersFetching,isLoading:isUsersLoading,status:statusUsers} = useSearchUsersQuery({
    key: key,
    value: debouncedSearchTermUser,
    limit: 1000,
  },{skip:!key});
  const [sortColumn, setSortColumn] = useState<string | null>('created_at');  // Default sorting by Created At
  const [sortOrder, setSortOrder] = useState<boolean>(false);  // Default sorting order (descending)
 
  // Fetch groups data for dropdown
  const { data: groupsData, error: groupsError } = useGetGroupsQuery({
    skip: 0,
    limit: 1000,
    //@ts-ignore
    is_rep_group: null,
  });

  const { data: usersData, error: usersError, isFetching,isLoading:isfiltereddataLoading,refetch,status } = useGetUsersQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
    //@ts-ignore
    group_id_filter: groupId,
    user_id_filter: userId ?? '',
    order_by: sortColumn ?? '',
    order_by_decending: sortOrder,  // Sort order boolean (true if descending)
  });
  const handleGroupChange = (value: string | null) => {
    dispatch(setGroupId(value));
    setKey('')
  };
// Define table columns
  // @ts-ignore
  const getColumnEmailProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={key==="email"?searchTermUser:""}
        placeholder="Search Email"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("email")
          dispatch(setUserId(null));
          setSearchTermUser(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key==="email"&&searchTermUser ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
   // @ts-ignore
   const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
        placeholder="Search Full Name"
        allowClear
        style={{ width: 200 }}
      value={key==="full_name"?searchTermUser:""}
        onChange={(e)=>{
          setKey("full_name")
          dispatch(setUserId(null));
          setSearchTermUser(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key==="full_name"&&searchTermUser ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
    ),
 
 
  });

   // @ts-ignore
   const getColumnGroupProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{
        padding: 8,
        position: 'absolute',
        zIndex: 1050, // Ensures the dropdown appears above other elements
        background: 'white',
        transform: 'translateY(-170%)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
      }} onKeyDown={(e) => e.stopPropagation()}>
         <Select
        placeholder="Select Group"
        allowClear
        className="select-group-for-user"
        style={{ width: 200 }}
        onChange={handleGroupChange}
        defaultValue={groupId}
        showSearch
        optionFilterProp="children" // Search based on Option's children (full_name)
        filterOption={(input, option) =>
          //@ts-ignore
          option?.children.toLowerCase().includes(input.toLowerCase()) // Search by full_name
        }
      >
        {groupsData?.data?.map((group: Group) => (
        //@ts-ignore
          <Option key={group.name} value={group.id}>
            {group.name}
          </Option>
        ))}
      </Select>
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: groupId ? '#1677ff' :'rgba(82, 82, 82, 0.88)'}} />
    ),
 
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
const columns = [
  
  {
    title:" Full Name",
  
    dataIndex: 'full_name',
    key: 'full_name',
    
    sorter: true,  // Enable sorting
    ...getColumnSearchProps('full_name'),
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    key: 'scope',
    render: (text: string) => (text==="rep" ? 'Manager' : text),
    sorter: true,  // Enable sorting
  },
  {
    title: "Email",
    dataIndex: 'email',
    key: 'email',
    sorter: true,  // Enable sorting
    ...getColumnEmailProps('email'),
  },
  {
    title: 'Group Name',
    dataIndex: 'groups',
    key: 'groups',
    sorter: false,  // Disable sorting for the Group Name column
    ...getColumnGroupProps('groups'),
    render: (groups: { name: string }[]) =>
      groups?.map((group) => group.name).join(', ') || '-',
    
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    key: 'is_active',
    sorter: true,  // Enable sorting
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,  // Enable sorting for the Created At column
    render: (created_at: string) => new Date(created_at).toLocaleString(),  // Convert to local timezone
  },
];
useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedSearchTermUser(searchTermUser);  // Set debounced search term after delay
  }, 300);  // Adjust the delay as needed

  return () => {
    clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
  };
}, [searchTermUser]);
  useEffect(() => {
    if (usersError) {
      message.error('Failed to load users');
    }
  }, [usersError]);

  // const handleGroupChange = (value: string | null) => {
  //   dispatch(setGroupId(value));
  // };

  useEffect(() => {
    if(location.state?.route==="prevRouteGroup")return
    dispatch(setGroupId(null));  // Reset group filter on mount
  }, []);

  const fetchAllUsers = () => {
    dispatch(setGroupId(null));  // Clear the groupId filter to fetch all users
    dispatch(setUserId(null));
  };

  // Handle table change for sorting
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setKey('')
    dispatch(setUserId(null));
    setSortColumn(sorter.field);  // Update the column being sorted
    const sortOrder = sorter.order=="ascend"?true:false;
    setSortOrder(!sortOrder);   // Update the sort order
   
  };

  return (
    <>
    {isUsersLoading||isfiltereddataLoading?<Spin tip="Loading..." />:
    //  (userId&&usersData?.data?.length||key&& allUsers?.length||!key&&usersData?.data||searchTermUser||groupId)?
        <>
         
          <Button onClick={fetchAllUsers} style={{ marginLeft: 16, marginBottom: 16 }}>
            Fetch All Users
          </Button>
          <Table
            columns={columns}
            loading={isFetching||isUsersFetching}
            dataSource={userId?usersData?.data ||   []:key? allUsers: usersData?.data||   []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick && handleRowClick(record),
            })}
            title={()=><Flex justify="space-between" align="center" className='flexRow'>
            <Title level={2}>User List </Title>
            <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
              if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
              navigate(`/add-user`)
              }
            }}>+ Add New User</Button>
          </Flex>}

footer={() => <Pagination
  current={currentPage}
  pageSize={pageSize}
  total={usersData?.count}  // Assuming `total` is set dynamically based on `usersData`
  onChange={onPageChange}
  showSizeChanger={true}  // Allow page size change
  onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)}  // Handle page size changes
  pageSizeOptions={["5", '10', '20', '50', '100']}  // Set options for page size
/>
}
            rowClassName="clickable-row"
            onChange={handleTableChange}  // Trigger sorting on column change
          />

          
        </>
//         :
//         <Card
          
//           style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
//           cover={<Image alt="example" src={myImage} preview={false}/>}
//         >
//           <Meta title={<Title level={2} style={{
//             textAlign: "center",
//             marginBottom: "0px"
//           }}>
//          No Users is added yet
//           </Title>} description={
//           <div style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             gap: "5px"
//           }}>
// <Text style={{
//   color: "#595959",
//   fontSize: 16,
//   fontWeight: 400,
// }}>You haven’t added Users, let’s add 
// user now by click below.

// </Text>
//            <Button type='primary' onClick={()=>{
//                 if(!user ) return null
//                 if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
//                 if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
//                 navigate(`/add-user`)
//                 }
//             }}>+ Add New Users</Button>
//           </div>
//           }
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             alignSelf: "center",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//           />
          
//         </Card>
 }
    
    </>
  );
};

export default UsersTable;
