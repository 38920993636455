import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message, Flex, Button, Card, Image, Typography } from 'antd';
import { useGetInventoryProductsQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Item } from '../types.ts'; // Ensure correct import path
import { formatPrice } from '../utils';
import "../styles/ProductsTable.scss"; 
import myImage from '../assets/images/AddProduct.png';
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';
import Meta from 'antd/es/card/Meta';
const { Text } = Typography;
const truncateText = (text: string, wordLimit: number) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

const columns = [
  {
    title: 'Internal ID',
    dataIndex: 'internal_id',
    key: 'internal_id',
    sorter: (a: any, b: any) => a.internal_id.localeCompare(b.internal_id),
    render: (text: string) => <strong>{text}</strong>, 

  },
  {
    title: 'Internal Name',
    dataIndex: 'internal_name',
    key: 'internal_name',
    sorter: (a: any, b: any) => a.internal_name.localeCompare(b.internal_name),
  },
  
  {
    title: 'Description',
    dataIndex: 'blurb',
    key: 'blurb',
    sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
    render: (text: string) => (
      <p className="description-column"   dangerouslySetInnerHTML={{ __html: truncateText(text, 20) }}></p>
    ),
  },
  {
    title: 'Fulfilment',
    dataIndex: 'fulfilment_type',
    key: 'fulfilment_type',
    sorter: (a: any, b: any) => a.fulfilment_type.localeCompare(b.fulfilment_type),
  },
  {
    title: 'Primary Image URL',
    dataIndex: 'primary_image_url',
    key: 'primary_image_url',
    render: (text: string) => (
      <img src={text} alt="Product" style={{ width: '100px', height: '100px' }} />
    ),
  },
];

interface ProductsTableProps {
  handleRowClick: (record: Item) => void;
}

const ProductsTable: React.FC<ProductsTableProps> = ({ handleRowClick }) => {
  const { currentPage, pageSize, onPageChange,onPageSizeChange } = usePagination();
  const navigate = useNavigate();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const [getUser, { data: user }] = useLazyGetUserQuery();
  const { data, error, isLoading } = useGetInventoryProductsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  useEffect(() => {
    getUser();

    
  }, [getUser]);
  useEffect(() => {
    if (error) {
      message.error('Failed to load products');
    }
  }, [error]);

  return (
    <div className="products-table-container">
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>{ data?.length?
          <Table
          title={()=><Flex justify="space-between" align="center" className='flexRow'>
            <Title level={2}>Product List </Title>
            <Button type='primary' onClick={()=>{
              if(!user ) return null
              if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
              if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
              navigate(`/add-product`)
              }
            }}>+ Add New Product</Button>
          </Flex>}
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
            footer={() => <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={10}
              onChange={onPageChange}
              showSizeChanger={true}  // Allow page size change
              onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)} // Handle page size changes
              pageSizeOptions={["5",'10', '20', '50', '100']}  // Set options for page size
            />
            }
          />: <Card
          
          style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
          cover={<Image alt="example" src={myImage} preview={false}/>}
        >
          <Meta title={<Title level={2} style={{
            textAlign: "center",
            marginBottom: "0px"
          }}>No Product is added yet</Title>} description={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          }}>
<Text style={{
  color: "#595959",
  fontSize: 16,
  fontWeight: 400,
}}>You haven’t added Product, let’s add 
now by click below..</Text>
           <Button type='primary' onClick={()=>{
                if(!user ) return null
                if((activeTab==('tab1')||activeTab==('tab2'))&&user?.scope === 'rep') return null;
                if (user?.scope === ('internal_admin')||user?.scope === ('rep')) {
                navigate(`/add-product`)
                }
            }}>+ Add New Product</Button>
          </div>
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          />
          
        </Card>
}
        </>
      )}
    </div>
  );
};

export default ProductsTable;
