import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Button, Pagination, Row, Col, Card, Flex } from 'antd';
import {
  useGetCampaignsQuery,
  useDownloadReportMutation,
  useInviteUsersMutation,
  useGetInviteStudentsQuery,
} from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts';
import {
  CopyOutlined,
  DownloadOutlined,
  StepBackwardFilled,
} from '@ant-design/icons';
import CSVUploader from './CSVUploader';
import ContactsModal from './ContactsModal';
import LeaderBoardModal from './LeaderBoardModal';
// @ts-ignore
import { CSVLink, CSVDownload } from 'react-csv';

import { Typography } from 'antd';
import CampaignCard from './CampaignCard';
// @ts-ignore
import SvgIcon from '../assets/svg/Man';
import { convertUTCDateToLocalDate, formatWithCommas } from '../utils';
import '../styles/StudentCampaign.scss';
import Title from 'antd/es/typography/Title';
import Meta from 'antd/es/card/Meta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCashRegister,
  faDollarSign,
  faHandHoldingDollar,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FaArrowAltCircleLeft, FaStepBackward } from 'react-icons/fa';
import OrdersTable from './OrdersTable';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';


interface ParsedData {
  email_address: string;
  name: string;
}
const { Paragraph } = Typography;

const StudentCampaigns: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [campaignId, setCampaignId] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const { data: studentData, isLoading: isLoadingStudents } =
    useGetInviteStudentsQuery({
      campaignId: campaignId ? campaignId : '',
      skip: 0,
      limit: 10,
    });
  const { currentPage, pageSize, onPageChange } = usePagination();
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const [downloadReport] = useDownloadReportMutation();
  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [leaderBoardModalVisible, setLeaderBoardModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectedContactsCampaignId, setSelectedContactsCampaignId] = useState<
    string | null
  >(null);

  useEffect(() => {

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [inviteUsers, { isLoading: isUploadingCSV }] = useInviteUsersMutation();
  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);

  const handleDataParsed = async (data: ParsedData[], id: string) => {
    console.log('Parsed Data:', data);
    await inviteUsers({ campaignId: id ?? '0', data });
    message.success('CSV file parsed successfully!');
  };

  const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link);
    message.success('Link copied to clipboard');
  };
  const handleNavigateToCampaign = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_FE}raise/${campaignId}/${user?.id}`;
  };
  const openContactsModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setContactsModalVisible(true);
  };

  const closeContactsModal = () => {
    setContactsModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const openLeaderBoardModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setLeaderBoardModalVisible(true);
  };

  const closeLeaderBoardModal = () => {
    setLeaderBoardModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'is_closed',
      key: 'is_closed',
      sorter: (a: Campaign, b: Campaign) => Number(a.is_closed) - Number(b.is_closed),
      render: (text: boolean) => (!text ? <Button style={{
        backgroundColor: "#EFFEF5",
        border: "1px solid #10A957",
        color: "#10A957",
        padding: 18,
        fontSize: "12px"
       
      }}>OPEN</Button> : <Button style={{
        backgroundColor: "#FFF0F0",
        border: "1px solid #FF5E5E",
        color:"#FF5E5E",
        padding: 18,
        fontSize: "12px"
       
      }}>CLOSED</Button>),
    },
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
 
    {
      title: 'Money Raised by Me',
      dataIndex: 'me_amount',
      key: 'me_amount',
      render: (text: string, record: Campaign) => (
       `$${formatWithCommas(record.me_amount)}`
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (text: string, record: Campaign) => `${convertUTCDateToLocalDate(
        new Date(record?.end_date) as Date
      )}`,
    },
  ];
console.log(user?.id,campaignId,'hij')
  return (
    <>
      {!campaignId ? (
        <>
       <Table
       title={()=>
       <Title level={2}>Campaign List </Title>
      
    }
       //@ts-ignore
       columns={isDesktop? columns:columns.slice(0,3)}
       dataSource={data || []}
       pagination={false}
       rowKey="id"
       onRow={(record) => ({
        onClick: () => {
          navigate(`/view-campaign/${record?.id}`, { state: { campaignId:record?.id  } })
        },
      })}
       footer={
        ()=><Pagination
        current={currentPage}
        pageSize={pageSize}
        total={0}
        onChange={onPageChange}
      
        showSizeChanger={false}
      />
       }

     />
     
    </>
      ) : (
        <div style={{ marginTop: '-60px' }}>
          <Button
            type="primary"
            shape="circle"
            icon={<FaArrowAltCircleLeft />}
            onClick={() => {
              setCampaignId(null);
            }}
          />
          <Title level={4}>
            Campaigns -{' '}
            {data?.find((campaign) => campaign.id === campaignId)?.title}
          </Title>
          <div className="campaign-detail">
            <div className="button-container">
              <Button
                type="primary"
                className="button"
                onClick={handleNavigateToCampaign}
              >
                Open My Store
              </Button>
              <Button
                type="primary"
                className="button"
                onClick={() =>
                  copyToClipboard(
                    `${process.env.REACT_APP_BASE_URL_FE}raise/${campaignId}/${user?.id}`
                  )
                }
              >
                Copy Link 
              </Button>
            </div>
            <div className="card-container">
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faSackDollar}
                          size="lg"
                          color="#FFBB38"
                        />
                      </div>
                    </div>
                  }
                  title={`$${formatWithCommas(
                    data?.find((campaign) => campaign.id === campaignId)
                      ?.money_raised
                  )}`}
                  description="Raised by Me"
                />
              </Card>
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container purple">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faHandHoldingDollar}
                          size="lg"
                          color="#396AFF"
                        />
                      </div>
                    </div>
                  }
                  title={'23'}
                  description="Sold by Me"
                />
              </Card>
            </div>
          </div>
          <Title level={4}>
           Contacts
          </Title>
          <Card className='campaign-contacts'>
                <Meta 
                avatar={
                  <Meta 
                  title={studentData?.count ?? "0"} 
                  description="Count" 
                />
                }
                title={<>
                <CSVUploader
                button={<Button  className="button" danger >
                Upload Contacts CSV
              </Button>}
            onDataParsed={(data) => handleDataParsed(data, campaignId)}
            loading={isUploadingCSV}
          />
              <Button  className="button" danger onClick={() => openContactsModal(campaignId)}>
                View Contacts
              </Button>
                </>} description={<Paragraph style={{marginBottom:0}}>
                  Not sure what to include?
                  <CSVLink
                    data={`name,email
          John Doe,jdoe@example.com`}
                  >
                    {' '}
                    Download our sample CSV
                  </CSVLink>
                  ;
                </Paragraph>
              }
            />
          </Card>
          <Title level={3}>Recent Orders</Title>
          <OrdersTable user_id={user?.id} campaign_id={campaignId}/>
        </div>
      )}
      {selectedContactsCampaignId && (
        <ContactsModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId}
          visible={contactsModalVisible}
          onClose={closeContactsModal}
        />
      )}
      {leaderBoardModalVisible && (
        <LeaderBoardModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId ?? ''}
          visible={leaderBoardModalVisible}
          onClose={closeLeaderBoardModal}
        />
      )}
      {/* <Paragraph>
        Not sure what to include?
        <CSVLink 
         data ={`name,email
John Doe,jdoe@example.com`}
         >
          {''}Download our sample CSV
         </CSVLink>
        
         
         ;



       </Paragraph> */}
    </>
  );
};

export default StudentCampaigns;
