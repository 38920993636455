// src/components/ContactsModal.tsx

import React, { useEffect, useState } from 'react';
import { Modal, Table, Pagination, Spin } from 'antd';
import { useGetInviteStudentsQuery } from '../services/admin/inventoryApi';
import { Campaign } from '../types.ts';
import { convertUTCDateToLocalDate } from '../utils';

interface ContactsModalProps {
  campaignId: string;
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
}

const ContactsModal: React.FC<ContactsModalProps> = ({ campaignId, visible, onClose,isMobile }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { data: studentData, isLoading: isLoadingStudents } = useGetInviteStudentsQuery({
    campaignId,
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  useEffect(() => {
    

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email_address',
      key: 'email_address',
      sorter: (a: any, b: any) => a.email_address.localeCompare(b.email_address),
      render: (text: string) => (isDesktop?text : `${text.slice(0,18)}...`),
       width:!isDesktop?"100px":'33%'
    },
   
    {
        title: 'Sent',
        dataIndex: 'is_sent_email_1',
        key: 'is_sent_email_1',
        sorter: (a: any, b: any) => Number(a.is_sent_email_1) - Number(b.is_sent_email_1),
        render: (text: boolean) => (text ? 'Yes' : 'No'),
         width:'33%'
      },
      {
        title: 'Added',
        dataIndex: 'created_at',
        sorter: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        render: (text: string) => {
          const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
        
          return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
        },
        key: 'created_at',
         width:'33%'
      },
  ];


  return (
    <Modal
      title="View Contacts"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="80%"
    >
      <Spin spinning={isLoadingStudents}>
        <Table
          columns={!isDesktop?columns.slice(0,2):columns}
          dataSource={studentData?.data ||  []}
          pagination={false}
          rowKey="email_address"
          scroll={{ x: isMobile?'100vw':undefined }}
        />
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={studentData?.count || 0}
          onChange={handlePageChange}
        />
      </Spin>
    </Modal>
  );
};

export default ContactsModal;
