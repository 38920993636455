import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Menu, Breadcrumb, Button, Drawer, MenuProps, Flex, Modal } from 'antd';
import { FlagFilled, HomeFilled, LoginOutlined, MenuOutlined, NotificationOutlined, ProfileFilled, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/logo.png';
import { signOut } from '../slices/admin/adminUserSlice';
import ProductsTable from './ProductsTable';
import CatalogsTable from './CatalogsTable';
import GroupsTable from './GroupsTable';
import CampaignsTable from './CampaignsTable';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';
import '../styles/Dashboard.scss';
import { Campaign, Catalog, Group, Item, User } from '../types.ts';
import { RootState } from '../store';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import Unauthorized from './UnAuthorised';
import UpdateUser from './UpdateUser';
import UsersTable from './UsersTable';
import OrdersTable from './OrdersTable';
import ReportsTable from './ReportsTable';
import StudentCampaigns from './StudentCampaigns';
import ChangePassword from './ChangePassword';
import ProfileSetup from './StudentDashboard';
import {

  ShoppingOutlined,
  AppstoreOutlined,
  UsergroupAddOutlined,
  BulbOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { inventoryApi } from '../services/admin/inventoryApi';



const { Header, Content, Footer, Sider } = Layout;
const scopes = ['internal_admin', 'rep_main', 'internal_support',  'rep_sub', 'sponsor', 'sponsor_sub','rep'];

const buttonTextMap: Record<string, string> = {
};

const navigateMap: Record<string, string> = {
};

const Dashboard: React.FC = () => {
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getUser, { data: user }] = useLazyGetUserQuery();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk =()=>{
   
    dispatch(signOut());

        navigate('/signin');
  }
  const handleCancel=()=>{
    setIsModalOpen(false)
  }
  useEffect(() => {
    getUser();

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getUser]);

  const handleMenuClick = useCallback(
    (e: any) => {
      if (e.key === '9') {
        setIsModalOpen(true)
      } else {
        if (!isDesktop) {
          setDrawerVisible(false);
        }
        dispatch(setActiveTab(`${e.key}` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
        if(e.key==="tab6"){
          navigate(`/dashboard/users`)
        }else if(e.key==="tab7"){
          navigate(`/dashboard/orders`)
        }else if(e.key==="tab1"){
          navigate(`/dashboard/products`)
        }else if(e.key==="tab2"){
          navigate(`/dashboard/catalogs`)
        }else if(e.key==="tab3"){
          navigate(`/dashboard/groups`)
        }else if(e.key==="tab4"){
          navigate(`/dashboard/campaigns`)
        }else if(e.key==="tab8"){
          navigate(`/dashboard/reports`)
        }else if(e.key==="tab5"){
          navigate(`/dashboard/user-profile`)
        }else if(e.key==="tab11"){
          navigate(`/dashboard/user-dashboard`)
        }else if(e.key==="tab9"){
          navigate(`/dashboard/user-campaigns`)
        }else{
          navigate(`/dashboard`)
        }
      }
    },
    [dispatch, navigate, isDesktop]
  );

  const items2: MenuProps['items'] = [
    {
      key: 'sub1',
      icon: React.createElement(NotificationOutlined),
      label: 'Manage',
      children: [
        ...(user?.scope === 'internal_admin'
          ? [
              { key: 'tab1', label: 'Products', icon: React.createElement(ShoppingOutlined) },
              { key: 'tab2', label: 'Catalogs', icon: React.createElement(AppstoreOutlined) },
            ]
          : []), // Only show Products and Catalogs if scope is internal_admin
        { key: 'tab3', label: 'Groups', icon: React.createElement(UsergroupAddOutlined) },
        { key: 'tab4', label: 'Campaigns', icon: React.createElement(BulbOutlined) },
        { key: 'tab6', label: 'Users', icon: React.createElement(UserOutlined) },
        { key: 'tab7', label: 'Orders', icon: React.createElement(ShoppingCartOutlined) },
        // { key: 'tab8', label: 'Reports', icon: React.createElement(BarChartOutlined) },
      ],
    },
    {
      key: 'sub3',
      icon: React.createElement(UserOutlined),
      label: 'Account',
      children: [
        { key: 'tab11', label: 'Dashboard', icon: React.createElement(HomeFilled) },
        { key: 'tab5', label: 'Profile', icon: React.createElement(ProfileFilled) },
       
        { key: 'tab9', label: 'Campaigns', icon: React.createElement(FlagFilled) },
        { key: '9', label: 'Sign Out', icon: React.createElement(LoginOutlined) },
      ],
    },
  ];

  const handleRowClick = (record: Item) => {
    if (!user) return null;
    if (user.scope === 'internal_admin'||user.scope.includes('rep')) navigate(`/edit-product/${record.id}`);
  };

  const handleCatalogRowClick = (record: Catalog) => {
    if (!user) return null;
    if (user.scope === 'internal_admin'||user.scope === 'rep' ) navigate(`/edit-catalog/${record.id}`);
  };

  const handleGroupRowClick = (record: Group) => {
    if (!user) return null;
    if (user.scope === 'internal_admin'||user.scope.includes('rep')) navigate(`/edit-groups/${record.id}`);
  };

  const handleCampaignRowClick = (record: Campaign) => {
    if (!user) return null;
    if (user.scope === 'internal_admin'||user.scope.includes('rep')) navigate(`/edit-campaign/${record.id}`);
  };

  const handleUserRowClick = (record: User) => {
    if (!user) return null;
    if (user.scope === 'internal_admin'||user.scope.includes('rep')) navigate(`/edit-user/${record.id}`);
  };

  const renderAddButton = () => {
    
    if (!user || !buttonTextMap[activeTab]) return null;
    
  
 if((activeTab==('tab1')||activeTab==('tab2'))&&user.scope === 'rep') return null;
    if (user.scope === ('internal_admin')||user.scope === ('rep')) {
      return (
        <Button
          type="primary"
          onClick={() => navigate(navigateMap[activeTab])}
          className="add-product-button"
        >
          {buttonTextMap[activeTab]}
        </Button>
      );
    }
    return null;
  };

  return (
  <>
  <Header className={isDesktop?"dashboard-header":"dashboard-header-mobile"}>
          <div  style={{
            width:"100%",
            lineHeight:"40px"
          }}>
          <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            mode="inline"
            
            selectedKeys={[activeTab]}
            defaultOpenKeys={['sub1', 'sub3']}
            style={{ height: '100%', borderRight: 0 }}
            items={user && scopes.includes(user?.scope) ? items2 : items2.filter(item => item?.key === 'sub3')}
            onClick={handleMenuClick}
          />
        </Drawer>
        <div className="header-content">
            {!isDesktop && (
              <Flex justify="space-between" align="center">
  <div style={{  backgroundColor: "white", width:"100%"}}>
          <img
            src={logo} alt="Company Logo"
            style={{ maxWidth: '100px',display:"flex",alignSelf:"center" }}
          />
        </div>
        <Button
              style={{
                backgroundColor:"#fff",
                boxShadow: "none",
                marginRight: '16px',
                color: "black"
              }}
                className="menu-toggle"
                type="primary"
                icon={<MenuOutlined size={20} />}
                onClick={() => setDrawerVisible(true)}
              />
              </Flex>
            
            )}
               
            {renderAddButton()}
            </div>
          </div>
        </Header>
        <Content >
       
          <div className="site-layout-content">
            {activeTab === 'tab1' && (user && scopes.includes(user?.scope) ? <ProductsTable handleRowClick={handleRowClick} /> : <Unauthorized />)}
            {activeTab === 'tab2' && (user && scopes.includes(user?.scope) ? <CatalogsTable handleRowClick={handleCatalogRowClick} /> : <Unauthorized />)}
            {activeTab === 'tab3' && (user && scopes.includes(user?.scope) ? <GroupsTable handleRowClick={handleGroupRowClick} /> : <Unauthorized />)}
            {activeTab === 'tab6' && (user && scopes.includes(user?.scope) ? <UsersTable handleRowClick={handleUserRowClick} /> : <Unauthorized />)}
            {activeTab === 'tab7' && (user && scopes.includes(user?.scope) ? <OrdersTable /> : <Unauthorized />)}
            {activeTab === 'tab8' && (user && scopes.includes(user?.scope) ? <ReportsTable isMobile={!isDesktop} /> : <Unauthorized />)}
            {activeTab === 'tab9' && <StudentCampaigns isMobile={!isDesktop} />}
            
            {activeTab === 'tab4' && <CampaignsTable handleRowClick={handleCampaignRowClick} />}
            {activeTab === 'tab5' && <UpdateUser />}
            {/* {activeTab === 'tab10' && <ChangePassword />} */}
            {activeTab === 'tab11' && <ProfileSetup />}
          </div>
        </Content>
        <Modal title="Sign-Out from account
Confirmation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you would like to sign out?</p>

      </Modal>

       </>

  );
};

export default Dashboard;
