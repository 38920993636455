// src/components/Reports.tsx

import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Button, Pagination, Modal, Card, Image, Typography, Input } from 'antd';
import Papa from 'papaparse';
import { useGetCampaignsQuery, useDownloadReportMutation, useSearchCampaignsQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';
import Title from 'antd/es/typography/Title';
import Meta from 'antd/es/card/Meta';
import myImage from '../assets/images/NoReport.png';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';


const { Text } = Typography;



const ReportsTable: React.FC<{isMobile: boolean}>= ({isMobile}) => {
  const { currentPage, pageSize, onPageChange } = usePagination();
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const { data:campaignData , isFetching:isCampaignsFetching,isLoading:isCampaignsLoading,status} = useSearchCampaignsQuery({
    key: key,
    value: value,
    limit: 1000,
  });
  const [downloadReport,{data:csv}] = useDownloadReportMutation();
  const [getUser, { data: user }] = useLazyGetUserQuery();

  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);
  useEffect(() => {
    getUser();

   
  }, [getUser]);
  const showModal = (recordId: string) => {
    if(!recordId) return
    recordId&& setId(recordId)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDownload = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product'| 'finance_based_order'|'based_frm_xml') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReport({ campaignId, reportType }).unwrap();
  
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        if(reportType==='based_frm_xml'){
          link.setAttribute('download', `report_${campaignId}_${reportType}.xml`);
        }else{
          link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        }
       
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      message.error('Failed to download report');
    }
  };
// Define table columns
  // @ts-ignore
  const getColumnCampaignNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Input"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("title")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="title"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });
   // @ts-ignore
   const getColumnGroupNameProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Input"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("group_name")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="group_name"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
  });

  // Define table columns
  // @ts-ignore
  const getColumnCampaigIdProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    //@ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
         <Input
        value={value}
        placeholder="Search Input"
        allowClear
        style={{ width: 200 }}
      
        onChange={(e)=>{
          setKey("pre_sale_id")
          setValue(e.target.value)
        }}
     />
    
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: key=="pre_sale_id"&&value ?   '#1677ff':'rgba(82, 82, 82, 0.88)' }} />
    ),
 
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  const columns = [
    {
      title: 'Pre Sale ID',
      dataIndex: 'pre_sale_id',
      key: 'pre_sale_id',
    sorter: (a: Campaign, b: Campaign) => a.pre_sale_id - b.pre_sale_id,
    ...getColumnCampaigIdProps('pre_sale_id')
    },
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      ...getColumnCampaignNameProps('name')
    },
    {
      title: 'Group Name',
      dataIndex: 'group',
      key: 'group',
      render: (text: string, record: Campaign) => (
        <Text>{record.group.name}</Text>
      ),
   ...getColumnGroupNameProps("group_id")
    },
    {
      title: 'Download Report',
      dataIndex: 'downloadReport',
      key: 'downloadReport',
      render: (text: string, record: Campaign) => (
        <Button onClick={()=>showModal(record.id)} icon={<DownloadOutlined />}> { 'Download  Reports'}</Button>
      ),
    },
 

  ];
  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>{data?.length?
          <Table
          title={()=>
            <Title level={2}>Report List </Title>
           
         }
            columns={columns}
            dataSource={key?campaignData:data || []}
            pagination={false}
            loading={isCampaignsLoading||isLoading||isCampaignsFetching}
            rowKey="id"
            footer={() => <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={0}
              onChange={onPageChange}
              showSizeChanger={false}
            />
            }
          />: <Card
          
          style={{ width: "55%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
          cover={<Image alt="example" src={myImage} preview={false}/>}
        >
          <Meta title={<Title level={2} style={{
            textAlign: "center",
            marginBottom: "0px"
          }}>
          No Reports is added yet</Title>} description={
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
          }}>
<Text style={{
  color: "#595959",
  fontSize: 16,
  fontWeight: 400,
}}>No Report have been added yet. Check after some time.</Text>
           
          </div>
          }
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
          />
          
        </Card>
          }
          <Modal title="Download Reports" open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel}>
          <Button onClick={() => handleDownload(id, "based_order")} icon={<DownloadOutlined />} style={{
            width:"100%",
            marginBottom: 10
          }}> { 'Download Order Report'}</Button>
          <Button onClick={() => handleDownload(id, "based_user")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >{ 'Download User Report'}</Button>
          <Button onClick={() => handleDownload(id, "based_product")} icon={<DownloadOutlined />}
          style={{
            width:"100%",
            marginBottom: 10
          }}
          >  {'Download Product Report'}</Button>
         {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "finance_based_order")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download Accounting Report </Button>}
            {user?.scope ==="internal_admin"&& <Button onClick={() => handleDownload(id, "based_frm_xml")} icon={<DownloadOutlined />}
         style={{
          width:"100%",
          marginBottom: 10
        }}
         > Download FRM XML Report </Button>}
      </Modal>
        </>
      )}
    </>
  );
};

export default ReportsTable;
