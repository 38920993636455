import React from 'react';
import { Tag as AntTag, Checkbox } from 'antd';
import { Tag } from '../types.ts';


interface SelectTagsProps {
  tagsData: Tag[];
  setSelectedTags: (tagId: number, checked: boolean) => void;
  selectedTags:number[]
}

const SelectTags: React.FC<SelectTagsProps> = ({ tagsData,setSelectedTags,selectedTags }) => {
  // Initialize with tag IDs (number type)
  
  const handleChange = (tagId: number, checked: boolean) => {
    setSelectedTags(tagId, checked);
  };
console.log(selectedTags,tagsData)
  return (
    <div style={{ display: 'flex', gap: 4, flexWrap: 'wrap', alignItems: 'center' }}>
     {tagsData.map((tag) => (
  <Checkbox
    key={tag.id}
    checked={selectedTags.includes(tag.id)} // Check if the tag ID is selected
    onChange={(e) => handleChange(tag.id, e.target.checked)} // Toggle tag by ID
    // style={{
    //   display: 'block', // Ensures each checkbox is on a new line (optional)
    //   userSelect: 'none',
    //   height: '53px',
    //   padding: '16px',
    //   fontSize: '16px',
    //   borderRadius: '0px',
    // }}
  >
    {tag.name} {/* Display the tag's name */}
  </Checkbox>
))}
    </div>
  );
};

export default SelectTags;
